const getDefaultState = () => {
  return {};
};
/* eslint-disable */
const state = getDefaultState();
export default {
  namespaced: true,
  name: "alert",
  state: {
    alert: {},
  },
  mutations: {
    setAlert(state, showAlert) {
      state.alert = { ...showAlert };
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
