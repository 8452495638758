<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-data-table
            class="clickable-table"
            :headers="headers"
            :items="items"
            @click:row="userClickHandler"
            :loading="isRequestWaiting"
            :options.sync="options"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
            }"
          >
            <template v-slot:item.icon="{}">
              <v-icon color="primary">mdi-account-group</v-icon>
            </template>
            <template v-slot:item.edit="{ item }">
              <v-btn @click.stop="openEditEmployee(item)" color="primary" icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-btn
                v-if="isSuperAdminPermission"
                @click.stop="openDeletePopUp(item)"
                class="white--text"
                color="red"
                icon
              >
                <v-icon>mdi-account-remove</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <DetailTeamPopUp
      :isOpened="isDetailTeamOpened"
      :selectedItem="selectedItem"
      @close="isDetailTeamOpened = false"
    ></DetailTeamPopUp>
    <EditTeamPopUp
      :isOpened="isEditPopUpOpened"
      :selectedItem="selectedItem"
      @update="updateHandler($event)"
      @close="closeEditEmployee"
    ></EditTeamPopUp>
    <ConfirmPopUp
      :question="'Вы действительно хотите удалить этого сотрудника?'"
      :isOpened="isDeletePopUpOpened"
      @yesAction="deleteTeam"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp>
  </v-row>
</template>

<script>
import EditTeamPopUp from "../popUps/EditTeamPopUp.vue";
import ConfirmPopUp from "../../shared/ConfirmPopUp.vue";
import {
  GET_TEAMS_IN_CALLCENTER_URL,
  GET_DELETE_TEAM_IN_CALLCENTER_URL,
} from "../../../config/http";
import appUIService from "../../../utils/appUIService";
import DetailTeamPopUp from "../popUps/DetailTeamPopUp.vue";

import DataTableMixin from "../../../mixins/DataTableMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import TabMixin from "../../../mixins/TabMixin";

export default {
  name: "CallCenterTeamTab",
  mixins: [DataTableMixin, RoleMixin, TabMixin],
  data: () => ({
    headers: [
      { text: "", value: "icon", sortable: false, width: 36 },
      { text: "Название", value: "name", sortable: true },
      { text: "Количество участников", value: "Users.length", sortable: true },
      {
        text: "Количество менеджеров",
        value: "teamAdmins.length",
        sortable: true,
      },
      { text: "", value: "edit", sortable: false, width: 36 },
      { text: "", value: "delete", sortable: false, width: 36 },
    ],
    isDetailTeamOpened: false,
  }),
  created() {
    appUIService.getInstance().teamAddedCallback = this.addHandler;
    console.log(appUIService.getInstance());
  },
  destroyed() {
    appUIService.getInstance().teamAddedCallback = () => {};
  },
  methods: {
    watcherHandler() {
      this.getTeamsFromApi();
    },
    tabWatcherHandler() {
      this.getTeamsFromApi();
    },
    getTeamsFromApi() {
      this.isRequestWaiting = true;
      const params = {
        ...this.getQueryParams(),
        callCenterId: this.callCenterId,
      };

      this.$http
        .get(GET_TEAMS_IN_CALLCENTER_URL(), { params })
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.items = response.data.items;
            this.totalItems = response.data.count;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    userClickHandler(item) {
      console.log(item);
      this.selectedItem = item;
      this.isDetailTeamOpened = true;
    },
    openEditEmployee(employee) {
      this.selectedItem = employee;
      this.isEditPopUpOpened = true;
    },
    closeEditEmployee() {
      this.isEditPopUpOpened = false;
      this.selectedItem = null;
    },
    openDeletePopUp(item) {
      this.selectedItem = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedItem = null;
    },
    deleteTeam() {
      this.isRequestWaiting = true;
      this.$http
        .delete(GET_DELETE_TEAM_IN_CALLCENTER_URL(this.selectedItem.id))
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.deleteHandler();
            this.getTeamsFromApi();
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
  },
  components: {
    EditTeamPopUp,
    ConfirmPopUp,
    DetailTeamPopUp,
  },
  props: {
    callCenterId: Number,
  },
};
</script>

<style></style>
