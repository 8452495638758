<template>
    <v-row v-if="callCenter" class="pa-10" no-gutters>
        <v-col>
            <v-row>
                <v-col>
                    <span class="text-h6">
                        {{
                            `${!this.iAmAffManager ? `Колл-центр: ${callCenter.name}. ID колл-центра: ${callCenter.id}` : ""}`
                        }}
                    </span>
                </v-col>
                <!-- <v-col>
          <span class="text-h6">{{ `ID: ${callCenter.id}` }}</span>
        </v-col> -->
            </v-row>
            <v-row>
                <v-col>
                    <v-tabs v-model="selectedTab" @change="callCenterTabChanged" slider-color="primary" :show-arrows="true">
                        <v-tab v-for="(tab, index) in tabs" :key="index">
                            {{ tab.name }}
                            <v-btn v-if="isSuperAdminPermission && tab.withInviteUser" class="ml-2"
                                @click.stop="isInviteUserOpened = true" icon>
                                <v-icon color="primary">mdi-account-plus</v-icon>
                            </v-btn>
                            <v-btn v-if="isSuperAdminPermission && tab.withInviteEmployee" class="ml-2"
                                @click.stop="isInviteEmployeeOpened = true" icon>
                                <v-icon color="primary">mdi-account-plus</v-icon>
                            </v-btn>
                            <!-- <v-btn
                v-if="isSuperAdminPermission && tab.withInviteTeam"
                class="ml-2"
                @click.stop="isCreateTeamOpened = true"
                icon
              > -->
                            <!-- <v-icon color="primary">mdi-account-plus</v-icon>
              </v-btn> -->
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
            <v-card class="pa-5" elevation="4">
                <v-tabs-items touchless v-model="selectedTab" :value="selectedTab">
                    <v-tab-item v-for="(item, index) in tabs" :key="item.tab">
                        <v-card flat>
                            <component :is="item.component" :callCenterId="getCallCenterId" :tabIndex="index"></component>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-col>
        <CreateUserPopUp :isOpened="isInviteUserOpened" :callCenterId="getCallCenterId" @close="isInviteUserOpened = false">
        </CreateUserPopUp>
        <InviteEmployeePopUp :isOpened="isInviteEmployeeOpened" :callCenterId="getCallCenterId"
            @close="isInviteEmployeeOpened = false"></InviteEmployeePopUp>
        <!-- <CreateTeamPopUp
      :isOpened="isCreateTeamOpened"
      :callCenterId="getCallCenterId"
      @close="isCreateTeamOpened = false"
    ></CreateTeamPopUp> -->
    </v-row>
</template>

<script>
import CallCenterStatisticsTab from "../callCenterPage/tabs/CallCenterStatisticsTab.vue";
import CallCenterEmployeesTab from "../callCenterPage/tabs/CallCenterEmployeesTab.vue";
import CallCenterTeamTab from "../callCenterPage/tabs/CallCenterTeamTab.vue";
import CallCenterUsersTab from "../callCenterPage/tabs/CallCenterUsersTab.vue";
import CallCenterLeadsTab from "../callCenterPage/tabs/CallCenterLeadsTab.vue";
import CallCenterClientsTab from "../callCenterPage/tabs/CallCenterClientsTab.vue";
import CallCenterProcessedUsersTab from "../callCenterPage/tabs/CallCenterProcessedUsersTab.vue";
import CallCenterNotProcessedUsersTab from "../callCenterPage/tabs/CallCenterNotProcessedUsersTab.vue";
import CallCenterProcessedLaterTab from "../callCenterPage/tabs/CallCenterProcessedLaterTab.vue";
import AffManLeadsTab from "../callCenterPage/tabs/AffManLeadsTab.vue";
import AffManClientsTab from "../callCenterPage/tabs/AffManClientsTab.vue";



import InviteEmployeePopUp from "../callCenterPage/popUps/InviteEmployeePopUp.vue";
import CreateTeamPopUp from "../callCenterPage/popUps/CreateTeamPopUp.vue";
import CreateUserPopUp from "../callCenterPage/popUps/CreateUserPopUp.vue";

import RoleMixin from "../../mixins/RoleMixin";

import { GET_CALL_CENTER_URL } from "../../config/http";

export default {
    name: "CallCenterPage",
    mixins: [RoleMixin],
    data: () => ({
        selectedTab: 0,
        tabs: [],
        isInviteUserOpened: false,
        isInviteEmployeeOpened: false,
        isCreateTeamOpened: false,
        callCenter: null,
    }),
    created() {
        this.getCallCenterFromApi();
        this.createTabs();
        this.selectTabFromUrl();
    },
    methods: {
        getCallCenterFromApi() {
            if (this.$store.getters.callCenter) {
                this.callCenter = this.$store.getters.callCenter;
                return;
            }

            this.isRequestWaiting = true;
            this.$http
                .get(GET_CALL_CENTER_URL(this.getCallCenterId))
                .then((response) => {
                    response.json().then((response) => {
                        // console.log(response);
                        this.callCenter = response.data.item;
                        this.$nextTick(() => {
                            this.selectTabFromUrl();
                        });
                    });
                })
                .catch((e) => {
                    console.error(e.message);
                })
                .finally(() => {
                    this.isRequestWaiting = false;
                });
        },
        selectTabFromUrl() {
            if (this.$route.params.tab) {
                this.selectedTab = parseInt(this.$route.params.tab);
            }
        },
        createTabs() {
            // console.log(this.$store.getters.roleId);
            // console.log(this.$store.getters.roleSuperAdmin.id);
            // console.log(this.iAmAdmin);
            if (this.isSuperAdminPermission) {
                this.tabs = [
                    {
                        name: "Участники",
                        component: CallCenterUsersTab,
                        withInviteUser: true,
                    },
                    {
                        name: "Лиды",
                        component: CallCenterLeadsTab,
                        withInviteUser: true,
                    },
                    {
                        name: "Клиенты",
                        component: CallCenterClientsTab,
                        withInviteUser: true,
                    },
                    {
                        name: "Сотрудники",
                        component: CallCenterEmployeesTab,
                        withInviteEmployee: true,
                    },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    {
                        name: "Статистика",
                        component: CallCenterStatisticsTab,
                    },
                    {
                        name: "Не обработанные клиенты",
                        component: CallCenterNotProcessedUsersTab,
                    },
                    {
                        name: "Обработать позже",
                        component: CallCenterProcessedLaterTab,
                    },
                    {
                        name: "Обработанные клиенты",
                        component: CallCenterProcessedUsersTab,
                    },
                ];
            } else if (this.iAmCRMManager) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Лиды",
                        component: CallCenterLeadsTab,
                        withInviteUser: true,
                    },
                    {
                        name: "Клиенты",
                        component: CallCenterClientsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            } else if (this.iAmSale) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Лиды",
                        component: CallCenterLeadsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Клиенты",
                    //   component: CallCenterClientsTab,
                    //   withInviteUser: true,
                    // },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            } else if (this.iAmRet) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    // {
                    //   name: "Лиды",
                    //   component: CallCenterLeadsTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Клиенты",
                        component: CallCenterClientsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            } else if (this.iAmSupport) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Лиды",
                        component: CallCenterLeadsTab,
                        withInviteUser: true,
                    },
                    {
                        name: "Клиенты",
                        component: CallCenterClientsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            } else if (this.iAmAffManager) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Лиды",
                        component: AffManLeadsTab,
                        withInviteUser: true,
                    },
                    {
                        name: "Клиенты",
                        component: AffManClientsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            } else if (this.iAmAffTeam) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Лиды",
                        component: CallCenterLeadsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Клиенты",
                    //   component: CallCenterClientsTab,
                    //   withInviteUser: true,
                    // },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            } else if (this.iAmRetHead) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Лиды",
                        component: AffManLeadsTab,
                        withInviteUser: true,
                    },
                    {
                        name: "Клиенты",
                        component: CallCenterClientsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            } else if (this.iAmRetTeam) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    // {
                    //   name: "Лиды",
                    //   component: CallCenterLeadsTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Клиенты",
                        component: CallCenterClientsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            } else if (this.iAmSaleTeam) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Лиды",
                        component: CallCenterLeadsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Клиенты",
                    //   component: CallCenterClientsTab,
                    //   withInviteUser: true,
                    // },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            } else if (this.iAmSaleHead) {
                this.tabs = [
                    // {
                    //   name: "Участники",
                    //   component: CallCenterUsersTab,
                    //   withInviteUser: true,
                    // },
                    {
                        name: "Лиды",
                        component: CallCenterLeadsTab,
                        withInviteUser: true,
                    },
                    // {
                    //   name: "Клиенты",
                    //   component: CallCenterClientsTab,
                    //   withInviteUser: true,
                    // },
                    // {
                    //   name: "Сотрудники",
                    //   component: CallCenterEmployeesTab,
                    //   withInviteEmployee: true,
                    // },
                    // {
                    //   name: "Команды",
                    //   component: CallCenterTeamTab,
                    //   withInviteTeam: true,
                    // },
                    // {
                    //   name: "Статистика",
                    //   component: CallCenterStatisticsTab,
                    // },
                    // {
                    //   name: "Не обработанные клиенты",
                    //   component: CallCenterNotProcessedUsersTab,
                    // },
                    // {
                    //   name: "Обработать позже",
                    //   component: CallCenterProcessedLaterTab,
                    // },
                    // {
                    //   name: "Обработанные клиенты",
                    //   component: CallCenterProcessedUsersTab,
                    // },
                ];
            }
        },
        userClickHandler() {
            // console.log(user);
        },
        callCenterTabChanged(value) {
            this.$router.push({
                name: "CallCenterPage",
                params: {
                    callCenterId: this.getCallCenterId,
                    tab: value,
                },
            });
        },
    },
    computed: {
        getCallCenterId() {
            return parseInt(this.$route.params.callCenterId);
        },
    },
    components: {
        CallCenterEmployeesTab,
        CallCenterTeamTab,
        InviteEmployeePopUp,
        CreateTeamPopUp,
        CallCenterUsersTab,
        CallCenterLeadsTab,
        CallCenterClientsTab,
        CreateUserPopUp,
        AffManLeadsTab,
        AffManClientsTab,
        CallCenterProcessedUsersTab,
        CallCenterProcessedLaterTab,
    },
};
</script>

<style></style>
