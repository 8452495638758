var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(" "+_vm._s(_vm.header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filterMinQuery || _vm.filterMaxQuery ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}])},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-row',{staticClass:"pl-4 pr-4"},[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Минимум","clearable":"","rules":[
              // ...rules.required
            ]},on:{"input":function($event){_vm.filterMinQuery = Math.abs(_vm.filterMinQuery)}},model:{value:(_vm.filterMinQuery),callback:function ($$v) {_vm.filterMinQuery=$$v},expression:"filterMinQuery"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Максимум","clearable":"","required":"","rules":[
              // ...rules.required
            ]},on:{"input":function($event){_vm.filterMaxQuery = Math.abs(_vm.filterMaxQuery)}},model:{value:(_vm.filterMaxQuery),callback:function ($$v) {_vm.filterMaxQuery=$$v},expression:"filterMaxQuery"}})],1)],1),_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Search ")]),_c('v-btn',{attrs:{"block":"","color":"white"},on:{"click":_vm.clear}},[_vm._v(" clear ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }