var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600","persistent":""},on:{"click:outside":_vm.closePopUp},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('v-card',{staticClass:"card-container-pop-up px-6 py-4"},[_c('v-row',[_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closePopUp}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto","align":"center"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" Изменить аккаунт ")])])],1),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(
          _vm.itemCopy &&
          (_vm.isSuperAdminPermission ||
            _vm.iAmSaleHead ||
            _vm.iAmCRMManager ||
            _vm.iAmSaleTeam ||
            _vm.iAmRetHead ||
            _vm.iAmSaleHead)
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-autocomplete',{ref:"isClient",attrs:{"items":_vm.isClient,"item-text":"name","item-value":"value","label":"Лид/клиент:","placeholder":"Назначить лидом/клиентом","loading":_vm.isRequestIsClientWaiting},on:{"input":function($event){return _vm.updateLocalItem(_vm.itemCopy, 'isClient', $event)},"dblclick":function($event){return _vm.$refs.isClient.blur()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)]}}],null,false,3167586048),model:{value:(_vm.selectedisClient),callback:function ($$v) {_vm.selectedisClient=$$v},expression:"selectedisClient"}})],1)],1):_vm._e(),(
          _vm.itemCopy &&
          (_vm.isSuperAdminPermission ||
            _vm.iAmSupport ||
            _vm.iAmRetHead ||
            _vm.iAmSaleHead ||
            _vm.iAmCRMManager)
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-autocomplete',{ref:"statusAccount",attrs:{"items":_vm.getAccountStatuses,"item-text":"name","item-value":"id","label":"Статус аккаунта","placeholder":"Статус аккаунта","loading":_vm.isRequestCallCentersWaiting},on:{"dblclick":function($event){return _vm.$refs.statusAccount.blur()}},model:{value:(_vm.selectedAccountStatus),callback:function ($$v) {_vm.selectedAccountStatus=$$v},expression:"selectedAccountStatus"}})],1)],1):_vm._e(),(_vm.itemCopy && (_vm.isSuperSuperAdminPermission || _vm.iAmCRMManager))?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-autocomplete',{ref:"callCenter",attrs:{"items":_vm.callCenters,"item-text":"name","item-value":"id","label":"Колл-центр","placeholder":"Колл-центр","loading":_vm.isRequestCallCentersWaiting},on:{"change":_vm.onCallCenterChanged,"dblclick":function($event){return _vm.$refs.callCenter.blur()}},model:{value:(_vm.selectedCallCenter),callback:function ($$v) {_vm.selectedCallCenter=$$v},expression:"selectedCallCenter"}})],1)],1):_vm._e(),(
          _vm.itemCopy &&
          _vm.selectedCallCenter &&
          (_vm.isSuperAdminPermission || _vm.iAmCRMManager)
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-autocomplete',{ref:"affManagerAdmin",attrs:{"items":_vm.affManagers,"item-text":function (item) { return ((item.firstName) + " " + (item.lastName) + " (" + (item.email) + ")"); },"item-value":"id","label":"Aff Manager","placeholder":"Aff Manager","loading":_vm.isRequestManagerWaiting},on:{"dblclick":function($event){return _vm.$refs.affManagerAdmin.blur()}},model:{value:(_vm.selectedAffManager),callback:function ($$v) {_vm.selectedAffManager=$$v},expression:"selectedAffManager"}})],1)],1):_vm._e(),(
          _vm.itemCopy &&
          _vm.selectedCallCenter &&
          (_vm.isSuperAdminPermission ||
            _vm.iAmCRMManager ||
            _vm.iAmSaleTeam ||
            _vm.iAmSaleHead)
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-autocomplete',{ref:"saleAdmin",attrs:{"items":_vm.saleManagers,"item-text":function (item) { return ((item.firstName) + " " + (item.lastName) + " (" + (item.email) + ")"); },"item-value":"id","label":"Sale Manager","placeholder":"Sale Manager","loading":_vm.isRequestManagerWaiting},on:{"dblclick":function($event){return _vm.$refs.saleAdmin.blur()}},model:{value:(_vm.selectedSaleManager),callback:function ($$v) {_vm.selectedSaleManager=$$v},expression:"selectedSaleManager"}})],1)],1):_vm._e(),(
          _vm.itemCopy &&
          _vm.selectedCallCenter &&
          (_vm.isSuperAdminPermission ||
            _vm.iAmCRMManager ||
            _vm.iAmRetTeam ||
            _vm.iAmRetHead)
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-autocomplete',{ref:"retManager",attrs:{"items":_vm.retManagers,"item-text":function (item) { return ((item.firstName) + " " + (item.lastName) + " (" + (item.email) + ")"); },"item-value":"id","label":"Ret Manager","placeholder":"Ret Manager","loading":_vm.isRequestManagerWaiting},on:{"dblclick":function($event){return _vm.$refs.retManager.blur()}},model:{value:(_vm.selectedRetManager),callback:function ($$v) {_vm.selectedRetManager=$$v},expression:"selectedRetManager"}})],1)],1):_vm._e(),(
          _vm.itemCopy &&
          (_vm.isSuperAdminPermission ||
            _vm.iAmCRMManager ||
            _vm.iAmSale ||
            _vm.iAmRet ||
            _vm.iAmRetTeam ||
            _vm.iAmSaleTeam ||
            _vm.iAmRetHead ||
            _vm.iAmSaleHead)
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-autocomplete',{ref:"processedStatus",attrs:{"items":_vm.getProcessedStatuses,"item-text":function (item) { return _vm.renderProcessingcStatus(item); },"item-value":"id","label":"Статус обработки","placeholder":"Статус обработки","loading":_vm.isRequestManagerWaiting},on:{"dblclick":function($event){return _vm.$refs.processedStatus.blur()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-badge',{attrs:{"inline":"","left":"","dot":"","color":_vm.processingStatusColor(data.item.id)}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"item",fn:function(data){return [_c('v-badge',{attrs:{"inline":"","left":"","dot":"","color":_vm.processingStatusColor(data.item.id)}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,false,805622588),model:{value:(_vm.selectedProcessedStatus),callback:function ($$v) {_vm.selectedProcessedStatus=$$v},expression:"selectedProcessedStatus"}})],1)],1):_vm._e(),(
          _vm.itemCopy &&
          (_vm.isSuperAdminPermission ||
            _vm.iAmCRMManager ||
            _vm.iAmSupport ||
            _vm.iAmRetTeam ||
            _vm.iAmRetHead ||
            _vm.iAmSaleHead)
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-autocomplete',{ref:"phoneStatus",attrs:{"items":_vm.getPhoneStatuses,"item-text":"name","item-value":"id","label":"Статус верификации телефона","placeholder":"Статус верификации телефона"},on:{"dblclick":function($event){return _vm.$refs.phoneStatus.blur()}},model:{value:(_vm.selectedPhoneStatus),callback:function ($$v) {_vm.selectedPhoneStatus=$$v},expression:"selectedPhoneStatus"}})],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.isRequestWaiting,"block":""}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }