<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      {{ itemCopy.user }}
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";

export default {
  name: "OpenContentInModal",
  mixins: [PopUpMixin],
  data: () => ({ itemCopy: "" }),
  methods: {
    isOpenedWatcher(value) {
      if (value) {
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        console.log(this.itemCopy);
      }
    },
  },
  computed: {},
  components: {},
};
</script>

<style scoped></style>
