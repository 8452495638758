import Vue from "vue";
import VueResource from "vue-resource";
import store from "./vuex";
import router from "../router/index";

import { REFRESH_TOKEN_URL } from "../config/http";

import { tokenKey, refreshTokenKey } from "../utils/cookiesUtility";

Vue.use(VueResource);

Vue.http.interceptors.push((request, next) => {
  const token = store.getters.token;
  if (token !== null) {
    request.headers.set("Content-Type", "application/json");
    request.headers.set("Access-Control-Allow-Origin", "*");
    request.headers.set("Authorization", "Bearer " + store.getters.token);
  }
  next();
});

Vue.http.interceptors.push((request, next) => {
  next((response) => {
    if (response.status === 401) {
      console.log("Refresh Token Сall");
      const refreshToken = store.getters.refreshToken;
      if (refreshToken !== null) {
        Vue.http
          .post(REFRESH_TOKEN_URL, { refreshToken })
          .then((response) => {
            response.json().then((data) => {
              console.log("Refresh Token Succeful");
              console.log(data);

              store.dispatch("trySetToken", {
                token: data.token,
              });
              store.dispatch("trySetRefreshToken", {
                refreshToken: data.refresh,
              });

              Vue.$cookies.set(tokenKey, data.token);
              Vue.$cookies.set(refreshTokenKey, data.refresh);
            });
          })
          .catch((e) => {
            console.error(e.message);
            store.dispatch("logout");
            router.push("/auth");
          });
      } else {
        store.dispatch("logout");
        router.push("/auth");
      }
    }
  });
});

export default VueResource;
