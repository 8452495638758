var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1200","persistent":""},on:{"click:outside":_vm.closePopUp},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('v-card',{staticClass:"px-6 py-4"},[_c('v-row',[_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closePopUp}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Информация о изменениях счета")])])],1),(_vm.itemCopy)?_c('v-row',{staticClass:"bids-table-container",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{staticClass:"thh-class",attrs:{"headers":_vm.headers,"items":_vm.history,"loading":_vm.isRequestWaiting},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormatDateTime(item.createdAt))+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.ChangerAdmin.firstName) + " " + (item.ChangerAdmin.lastName) + " (" + (item.ChangerAdmin.email) + ")"))+" ")]}},{key:"item.roleId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRoleById(item.roleId))+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comment)+" ")]}}],null,false,4150649743)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }