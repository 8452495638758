var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"clickable-table",attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.currentPage,"items-per-page":_vm.currentPerPage,"loading":_vm.isRequestWaiting,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
            'items-per-page-options': _vm.rowsPerPageItems,
          },"show-select":""},on:{"click:row":_vm.rowClickHandler,"update:options":function($event){_vm.options=$event},"pagination":_vm.setPage},scopedSlots:_vm._u([{key:"header.open",fn:function(ref){return [_c('thead',[_c('tr',[_c('th',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-left":"6px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Открыть документ в новой вкладке. ")])])],1)])])]}},{key:"item.payRequestStatusId",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s((" " + (_vm.formatPayRequestStatus(item.payRequestStatusId))))+" ")]}},{key:"item.open",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDocumentHandler(item.formUrl)}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1),_c('EditPayRequestStatusPopUp',{attrs:{"isDeposit":false,"isOpened":_vm.isEditPopUpOpened,"selectedItem":_vm.selectedItem},on:{"update":function($event){return _vm.updateHandler($event)},"refresh":_vm.getRequestsFromApi,"close":_vm.closeEditPopUp}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }