import moment from "moment";
import BigNumber from "bignumber.js";
import DataTableTop from "../components/shared/DataTableTop.vue";
import FilterComponent from "../components/shared/FilterComponent.vue";
import FilterProcessingStatusComponent from "../components/shared/FilterProcessingStatusComponent.vue";
import FilterManagersComponent from "../components/shared/FilterManagersComponent.vue";
import FilterCallCentersComponent from "../components/shared/FilterCallCentersComponent.vue";
import FilterPlatformComponent from "../components/shared/FilterPlatformComponent.vue";
import FilterRangeComponent from "../components/shared/FilterRangeComponent.vue";
import FilterDateRangeComponent from "../components/shared/FilterDateRangeComponent.vue";
import EditProcessingStatusPopUp from "../components/shared/EditProcessingStatusPopUp.vue";
import EditSeveralUsersPopUp from "../components/callCenterPage/popUps/EditSeveralUsersPopUp.vue";
import {GET_USER_PHONE_DETAILS_URL} from "@/config/http";

export default {
  name: "DataTableMixin",
  data: () => ({
    totalItems: 0,
    options: {},
    rowsPerPageItems: [5, 10, 20, 50, 100],
    items: [],
    selectedItem: null,
    isRequestWaiting: false,
    isCreatePopUpOpened: false,
    isEditPopUpOpened: false,
    isEditProcessingStatusPopUpOpened: false,
    isDeletePopUpOpened: false,
    isDetailPopUpOpened: false,

    searchQuery: "",
    filterEmailQuery: "",
    filterFirstNameQuery: "",
    filterLastNameQuery: "",
    filterCountryQuery: "",
    filterPhoneQuery: "",
    filterBalanceQuery: { min: 0, max: null },
    filterCreatedAtQuery: { min: 0, max: null },
    filterUpdateAtQuery: { min: 0, max: null },
    filterlastLoginDateQuery: { min: 0, max: null },
    filterProcessingStatusQuery: null,
    filterLastCommentQuery: "",
    filterCallCenterQuery: undefined,
    filterPlatformQuery: undefined,
    filterAffManagerQuery: undefined,
    filterSaleAdminQuery: undefined,
    filterRetAdminQuery: undefined,
    filterDepositsCountQuery: { min: 0, max: null },
    filterDepositsSumQuery: { min: 0, max: null },

    filterSourceQuery: "",
    filterMediumQuery: "",
    filterCampaingQuery: "",
    filterContentQuery: "",
  }),
  methods: {
    watcherHandler() {
      console.log("Fake watcher");
    },
    getQueryParams() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      //console.log(page);
      //console.log(itemsPerPage);

      const skipCount = (page - 1) * itemsPerPage;

      const params = {
        skip: skipCount > 0 ? skipCount : 0,
        limit: itemsPerPage,
        sortBy: sortBy[0],
        sortType: sortDesc[0] ? "asc" : "desc",
      };

      // console.log("Filter Params");
      // console.log(params);

      return params;
    },
    getParams(obj) {
      let params = {
        ...this.getQueryParams(),
        // email: this.filterEmailQuery,
        // firstName: this.filterFirstNameQuery,
        // lastName: this.filterLastNameQuery,
        // country: this.filterCountryQuery,
        // phone: this.filterPhoneQuery,
        ...obj,
      };
      console.log(!!this.filterEmailQuery);
      if (this.filterEmailQuery || this.filterEmailQuery === null)
        params["email"] = this.filterEmailQuery;

      if (this.filterFirstNameQuery || this.filterFirstNameQuery === null)
        params["firstName"] = this.filterFirstNameQuery;

      if (this.filterLastNameQuery || this.filterLastNameQuery === null)
        params["lastName"] = this.filterLastNameQuery;

      if (this.filterCountryQuery || this.filterCountryQuery === null)
        params["country"] = this.filterCountryQuery;

      if (this.filterPhoneQuery || this.filterPhoneQuery === null)
        params["phone"] = this.filterPhoneQuery;

      if (this.searchQuery) params["search"] = this.searchQuery;

      if (this.filterSourceQuery || this.filterSourceQuery === null)
        params["utm_source"] = this.filterSourceQuery;

      if (this.filterMediumQuery || this.filterMediumQuery === null)
        params["utm_medium"] = this.filterMediumQuery;

      if (this.filterCampaingQuery || this.filterCampaingQuery === null)
        params["utm_campaing"] = this.filterCampaingQuery;

      if (this.filterContentQuery || this.filterContentQuery === null)
        params["utm_content"] = this.filterContentQuery;

      if (this.filterProcessingStatusQuery)
        params["processingStatusId"] = this.filterProcessingStatusQuery;

      if (this.filterCallCenterQuery)
        params["callCenterId"] = this.filterCallCenterQuery;

      if (this.filterPlatformQuery)
        params["platformId"] = this.filterPlatformQuery;

      if (this.filterAffManagerQuery || this.filterAffManagerQuery === null)
        params["affManagerAdminId"] = this.filterAffManagerQuery;

      if (this.filterSaleAdminQuery || this.filterSaleAdminQuery === null)
        params["saleAdminId"] = this.filterSaleAdminQuery;

      if (this.filterRetAdminQuery || this.filterRetAdminQuery == null)
        params["retAdminId"] = this.filterRetAdminQuery;

      if (this.filterLastCommentQuery)
        params["latestComment"] = this.filterLastCommentQuery;
      if (this.filterBalanceQuery["max"]) {
        params["balanceFrom"] = this.filterBalanceQuery.min;
        params["balanceTo"] = this.filterBalanceQuery.max;
      }
      if (this.filterCreatedAtQuery["max"]) {
        params["createdAtFrom"] = this.filterCreatedAtQuery.min;
        params["createdAtTo"] = this.filterCreatedAtQuery.max;
      }
      if (this.filterUpdateAtQuery["max"]) {
        params["updateAtFrom"] = this.filterUpdateAtQuery.min;
        params["updatedAtTo"] = this.filterUpdateAtQuery.max;
      }
      if (this.filterlastLoginDateQuery["max"]) {
        params["lastLoginDateFrom"] = this.filterlastLoginDateQuery.min;
        params["lastLoginDateTo"] = this.filterlastLoginDateQuery.max;
      }
      if (this.filterDepositsCountQuery["max"]) {
        params["depositsCountFrom"] = this.filterDepositsCountQuery.min;
        params["depositsCountTo"] = this.filterDepositsCountQuery.max;
      }
      if (this.filterDepositsSumQuery["max"]) {
        params["depositsSumFrom"] = this.filterDepositsSumQuery.min;
        params["depositsSumTo"] = this.filterDepositsSumQuery.max;
      }

      return params;
    },
    addHandler(item) {
      this.items = [item, ...this.items];
      this.totalItems += 1;
    },
    updateHandler(selectedItem) {
      const items = [...this.items];
      const index = items.findIndex((item) => selectedItem.id === item.id);
      if (index === -1) {
        console.error("Update index not found");
        return;
      }
      items[index] = selectedItem;
      this.items = items;
    },
    updateSeveralItems(selectedItems) {
      const items = [...this.items];
      selectedItems.forEach((selectedItem) => {
        const index = items.findIndex((item) => selectedItem.id === item.id);
        if (index !== -1) {
          items[index] = selectedItem;
        }
      });
      this.items = items;
    },
    deleteHandler() {
      const items = [...this.items];
      const index = items.findIndex((item) => this.selectedItem.id === item.id);
      this.closeDeletePopUp();
      if (index === -1) {
        console.error("Index not found");
        return;
      }
      items.splice(index, 1);
      this.items = items;
    },
    deleteSeveralHandler(selectedItems) {
      const items = [...this.items];
      selectedItems.forEach((selectedItem) => {
        const index = items.findIndex((item) => selectedItem.id === item.id);

        if (index === -1) {
          console.error("Index not found");
          return;
        }
        items.splice(index, 1);
      });
      this.closeDeletePopUp();
      this.items = items;
    },
    getFormatDate(dateTime) {
      if (!dateTime) {
        return "";
      }
      return moment(dateTime).format("YYYY-MM-DD HH:mm");
    },
    openEditPopUp(item) {
      this.selectedItem = item;
      this.isEditPopUpOpened = true;
    },
    closeEditPopUp() {
      this.isEditPopUpOpened = false;
      this.selectedItem = null;
    },
    openDeletePopUp(item) {
      this.selectedItem = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedItem = null;
    },
    openDetailPopUp(item) {
      this.selectedItem = item;
      this.isDetailPopUpOpened = true;
    },
    closeDetailPopUp() {
      this.isDetailPopUpOpened = false;
      this.selectedItem = null;
    },

    openEditProcessingStatusPopUp(user) {
      this.selectedItem = user;
      this.isEditProcessingStatusPopUpOpened = true;
    },
    closeEditProcessingStatusPopUp() {
      this.isEditProcessingStatusPopUpOpened = false;
      this.selectedItem = null;
    },
    copyPhoneToClipboard(leadId) {
      this.$http
          .get(GET_USER_PHONE_DETAILS_URL(leadId))
          .then((response) => {
            response.json().then((response) => {
              const phone = response.data.phone;
              navigator.clipboard.writeText(phone)
            });
          })
          .catch((e) => {
            console.error(e);
          });
      // console.log(phone);
    },
    getVerificationIcon(item) {
      if (
        item.verificationStatusId === this.getNotVerificationStatus ||
        item.verificationStatusId === this.getInProgressVerificationStatus
      ) {
        return "mdi-help-circle";
      } else if (
        item.verificationStatusId === this.getRejectVerificationStatus
      ) {
        return "mdi-close-thick";
      } else if (
        item.verificationStatusId === this.getVerifiedVerificationStatus
      ) {
        return "mdi-check-decagram";
      }
    },
    parseBigNumber(number) {
      return new BigNumber(number).toFixed(2);
    },
  },
  components: {
    DataTableTop,
    FilterComponent,
    FilterProcessingStatusComponent,
    FilterManagersComponent,
    FilterCallCentersComponent,
    FilterPlatformComponent,
    EditProcessingStatusPopUp,
    FilterRangeComponent,
    FilterDateRangeComponent,
    EditSeveralUsersPopUp,
  },
  watch: {
    options: {
      handler() {
        this.watcherHandler();
      },
      deep: true,
    },
  },
};
