var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600","persistent":""},on:{"click:outside":_vm.closePopUp},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('v-card',{staticClass:"card-container-pop-up px-6 py-4"},[_c('v-row',[_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closePopUp}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto","align":"center"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" Изменить статус обработки ")])])],1),(_vm.itemCopy)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(
          _vm.isSuperAdminPermission ||
          _vm.iAmSale ||
          _vm.iAmRet ||
          _vm.iAmRetTeam ||
          _vm.iAmSaleTeam ||
          _vm.iAmRetHead ||
          _vm.iAmSaleHead
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-autocomplete',{ref:"processingStatus",attrs:{"items":_vm.getProcessedStatuses,"value":_vm.itemCopy.processingStatusId,"item-text":function (item) { return _vm.renderProcessingcStatus(item); },"item-value":"id","label":"Статус обработки","placeholder":"Статус обработки","rules":[].concat( _vm.rules.notNull ),"loading":_vm.isRequestManagerWaiting},on:{"input":function($event){return _vm.updateLocalItem(_vm.itemCopy, 'processingStatusId', $event)},"dblclick":function($event){return _vm.$refs.processingStatus.blur()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-badge',{attrs:{"inline":"","left":"","dot":"","color":_vm.processingStatusColor(data.item.id)}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"item",fn:function(data){return [_c('v-badge',{attrs:{"inline":"","left":"","dot":"","color":_vm.processingStatusColor(data.item.id)}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,false,805622588)})],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.isRequestWaiting,"block":""}},[_vm._v(" Сохранить ")])],1)],1)],1):_vm._e()],1),_c('ConfirmPopUp',{attrs:{"question":'Вы действительно хотите удалить этот телефон?',"isOpened":_vm.isDeletePopUpOpened},on:{"yesAction":_vm.deletePhone,"noAction":_vm.closeDeletePopUp,"close":_vm.closeDeletePopUp}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }