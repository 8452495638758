<template>
  <span>
    {{ header.text }}
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon
            small
            :color="filterMinQuery || filterMaxQuery ? 'primary' : ''"
          >
            mdi-filter
          </v-icon>
        </v-btn>
      </template>
      <div style="background-color: white; width: 535px">
        <!-- {{ header.text }} -->
        <v-row class="">
          <v-col>
            <DatePicker
              mode="dateTime"
              v-model="filterMinQuery"
              :timezone="timezone"
              :valid-hours="{ min: 0, max: 24 }"
              :minute-increment="1"
              is24hr
            />
          </v-col>
          <v-col>
            <DatePicker
              mode="dateTime"
              v-model="filterMaxQuery"
              :timezone="timezone"
              :valid-hours="{ min: 0, max: 24 }"
              :minute-increment="1"
              :min-date="new Date(filterMinQuery)"
              is24hr
            />
          </v-col>
        </v-row>

        <v-btn block @click="submit" color="primary"> Search </v-btn>
        <v-btn block @click="clear" color="white"> clear </v-btn>
      </div>
    </v-menu>
  </span>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  name: "FilterDateRangeComponent",
  mixins: [],
  props: {
    header: Object,
  },
  data: () => ({
    filterMinQuery: 0,
    filterMaxQuery: "",
    timezone: null,
  }),
  methods: {
    clear() {
      this.filterMinQuery = 0;
      this.filterMaxQuery = "";

      this.$emit("input", { min: 0, max: "" });
      this.$emit("update");
      // this.submit();
    },
    clearMin() {
      this.filterMinQuery = 0;
    },
    clearMax() {
      this.filterMaxQuery = "";
    },
    submit() {
      let min = this.filterMinQuery.toISOString(),
        max = this.filterMaxQuery.toISOString();
      // console.log({ min, max });

      if (min <= max) {
        this.$emit("input", { min, max });
        this.$emit("update");
      } else {
        this.$store.commit("alert/setAlert", {
          message:
            "максимальное значение должно быть больше или равно минимальному",
          color: "#555",
          icon: "code-less-than-or-equal",
        });
      }
    },
  },
  components: {
    // DateTimePicker,
    DatePicker,
  },
};
</script>

<style></style>
