<template>
  <v-app-bar class="upper-bar-container" fixed>
    <v-row align="center" no-gutters>
      <v-col class="pl-10" cols="7">
        <v-row align="center">
          <v-col cols="auto" v-if="!true">
            <span class="text-h4 font-weight-bold">CFDX</span>
          </v-col>
          <template v-if="isSuperAdminPermission || iAmCRMManager">
            <v-col
              v-for="(item, index) in currentNavMenu"
              :key="index"
              cols="auto"
            >
              <v-btn
                @click="navigate(item.namePath)"
                :outlined="index === selectedNavMenuIndex"
                :text="index !== selectedNavMenuIndex"
              >
                {{ item.title }}
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <!-- <v-col>
        <v-btn dark @click="setAlert"> Alert </v-btn>
        <v-btn dark @click="setAlertt"> Alertt </v-btn>
      </v-col> -->
      <v-col cols="4">
        <span>{{ getName }}</span>
      </v-col>
      <v-col cols="1">
        <v-btn @click="logout" icon>
          <v-icon color="primary" large>mdi-logout-variant</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { RemoveAllCookies } from "../../../utils/cookiesUtility";
import RoleMixin from "../../../mixins/RoleMixin";

export default {
  name: "UpperBar",
  mixins: [RoleMixin],
  data: () => ({
    currentNavMenu: [],
    superAdminNavMenu: [
      {
        title: "Профили",
        namePath: "ProfilesPage",
        childrensPath: ["ProfilesPage"],
      },
      /*{
        title: "Документы",
        namePath: "DocumentsPage",
      },
      {
        title: "Команды",
        namePath: "TeamsPage",
        childrensPath: ["TeamsPage"],
      },
      {
        title: "Сотрудники",
        namePath: "EmployeesPage",
        childrensPath: ["EmployeesPage"],
      },*/
      {
        title: "Колл-центры",
        namePath: "AllCallCentersPage",
        childrensPath: ["AllCallCentersPage", "CallCenterPage"],
      },
      {
        title: "Календарь",
        namePath: "CalendarPage",
        childrensPath: ["CalendarPage"],
      },
    ],
    selectedNavMenuIndex: 0,
  }),
  created() {
    this.selectCurrentHeader();
  },
  methods: {
    selectCurrentHeader() {
      if (this.isSuperSuperAdminPermission || this.iAmCRMManager) {
        this.currentNavMenu = this.superAdminNavMenu;
      }
    },
    navigate(namePath) {
      if (this.$route.name === namePath) {
        return;
      }
      this.$router.push({
        name: namePath,
      });
    },
    logout() {
      this.$store.dispatch("logout");
      RemoveAllCookies();
      this.$router.push({ name: "AuthPage" });
    },
    setAlert() {
      this.$store.commit("alert/setAlert", {
        message: "col",
        color: "primary",
        icon: "account",
      });
    },
    setAlertt() {
      this.$store.commit("alert/setAlert", {
        message: "lol",
        color: "green",
        icon: "phone",
      });
    },
  },
  computed: {
    getName() {
      return `${this.$store.getters.firstName} ${this.$store.getters.lastName}: ${this.getMyRole.name}`;
    },
  },
  watch: {
    $route(value) {
      this.selectedNavMenuIndex = this.currentNavMenu.findIndex((item) => {
        return (
          item.childrensPath.find((childrenPath) => {
            return childrenPath === value.name;
          }) !== undefined
        );
      });
    },
  },
};
</script>

<style scoped>
.upper-bar-container {
  max-height: 64px !important;
}
</style>
