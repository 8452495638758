<template>
  <v-dialog
    v-model="isOpened"
    max-width="400"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6">
            {{ isCredit ? "Изменить кредит" : "Изменить баланс" }}
          </span>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col>
          <v-btn color="primary" block @click="isIncreaseOpened = true">
            Увеличить
          </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" block @click="isDecreaseOpened = true">
            Уменьшить
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <EditBalancePopUp
      v-if="!isCredit"
      :isIncrease="isIncreaseOpened"
      :balanceChange="balanceChange"
      :isOpened="isIncreaseOpened || isDecreaseOpened"
      :selectedItem="itemCopy"
      @submit="update"
      @update="$emit('update', $event)"
      @close="
        () => {
          isIncreaseOpened = false;
          isDecreaseOpened = false;
          if ( balanceChange !== 0 ) {
            closePopUp();
          }
        }
      "
    ></EditBalancePopUp>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";

import EditBalancePopUp from "./EditBalancePopUp.vue";

export default {
  name: "EditBalanceChoosePopUp",
  mixins: [PopUpMixin, RoleMixin],
  props: {
    isCredit: Boolean,
    initialBalanceChange: Number,
  },
  data: () => ({
    number: 0,
    errorCode: null,
    isIncreaseOpened: false,
    isDecreaseOpened: false,
  }),
  methods: {
    update(isCredit, value) {
      if (isCredit) this.itemCopy.credit = value;
      else this.itemCopy.balance = value;

      this.$emit("update", this.itemCopy);
    },
  },
  watch: {
    isOpened: function(value) {
      if (value) {
        this.$nextTick(() => {
        if ( this.initialBalanceChange !== 0 ) {
          this.isIncreaseOpened = this.initialBalanceChange > 0;
          this.isDecreaseOpened = this.initialBalanceChange < 0;
        } 
        this.balanceChange = this.initialBalanceChange;
      })
    }
    },
  },
  components: {
    EditBalancePopUp,
  },
};
</script>

<style scoped></style>
