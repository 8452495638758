<template>
  <v-dialog
    v-model="isOpened"
    max-width="400"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6">
            <template>
              Вы собираетесь установить кредит {{ getName }}
              {{ number ? `равным ${number}` : "" }}, вы уверены?
            </template>
          </span>
        </v-col>
      </v-row>
      <v-form
        v-if="itemCopy"
        v-model="isValid"
        @submit.prevent="submitAction"
        ref="form"
      >
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="number"
              @change="inputChangeHandler"
              type="number"
              label="Сумма"
              placeholder="Сумма"
              :rules="[...rules.requiredNumber, ...getErrorRules()]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-2" no-gutters>
          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Подтвердить
            </v-btn>
          </v-col>
        </v-row>

        <!-- <v-row class="mt-2" justify="center" v-if="success || error">
          <v-col cols="auto">
            <div class="text-h6" v-if="!success && error">
              <v-icon color="black"> mdi-alert-circle-outline </v-icon> Ошибка
            </div>
            <div class="text-h6" v-if="success && !error">
              <v-icon color="black"> mdi-check-outline </v-icon> Успешно
            </div>
          </v-col>
        </v-row> -->
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";

import { GET_UPDATE_CREDIT_URL } from "../../../config/http";

export default {
  name: "EditCreditPopUp",
  mixins: [PopUpMixin, RoleMixin],

  data: () => ({
    number: 0,
    comment: "",
    // error: false,
    // errorCode: null,
    // success: false,
  }),
  methods: {
    submitAction() {
      const amount = this.number;

      const body = {
        credit: amount,
      };

      if (this.isSuperAdminPermission) {
        body["isWithoutLimit"] = true;
      }

      this.$http
        .patch(GET_UPDATE_CREDIT_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.$emit("submit", response.data.items[0].credit);

            this.$store.commit("alert/setAlert", {
              message: `Установлен кредит ${response.data.items[0].credit}`,
              color: "green",
              icon: "cash-multiple",
            });
          });
          this.$emit("close");
        })
        .catch((e) => {
          this.errorCode = e.body.code;
          this.$refs.form.validate();
          console.error(e.body.error);

          this.$store.commit("alert/setAlert", {
            message: "Не удалось установить кредит",
            color: "red",
            icon: "cash-multiple",
          });
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
    },
    getErrorRules() {
      return [
        () => this.errorCode !== 16 || "Ваше время вывода еще не пришло",
        () =>
          this.errorCode !== 17 ||
          "У вас недостаточно средств для вывода средств",
        () =>
          this.errorCode !== 18 ||
          "Достигнут максимальный вывод средств для статуса аккаунта",
      ];
    },
    inputChangeHandler() {
      this.errorCode = null;
      this.$refs.form.validate();
    },
  },
  computed: {
    getName() {
      return `${this.itemCopy.firstName} ${this.itemCopy.lastName}`;
    },
  },
};
</script>

<style scoped></style>
