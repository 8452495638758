<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-data-table
            class="clickable-table"
            :headers="headers"
            :items="items"
            :page="currentPage"
            :items-per-page="currentPerPage"
            @click:row="userClickHandler"
            :loading="isRequestWaiting"
            :options.sync="options"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
            }"
            @pagination="setPage"
          >
            <template v-if="isSuperAdminPermission" v-slot:item.icon="{}">
              <v-icon color="primary">mdi-account</v-icon>
            </template>
            <template v-slot:item.roleId="{ item }">
              {{ getRussianRoleName(item) }}
            </template>
            <!-- <template v-slot:item.adminTeams="{ item }">
              <span>
                {{ item.adminTeams.map((team) => team.name).join(", ") }}
              </span>
            </template> -->
            <template v-if="isSuperAdminPermission" v-slot:item.edit="{ item }">
              <v-btn @click.stop="openEditEmployee(item)" color="primary" icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <template
              v-if="isSuperAdminPermission"
              v-slot:item.delete="{ item }"
            >
              <v-btn
                v-if="isSuperAdminPermission"
                @click.stop="openDeletePopUp(item)"
                class="white--text"
                color="red"
                icon
              >
                <v-icon>mdi-account-remove</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <DetailEmployeePopUp
      :isOpened="isDetailEmployeeOpened"
      :selectedItem="selectedEmployee"
      @close="isDetailEmployeeOpened = false"
    ></DetailEmployeePopUp>
    <EditEmployeePopUp
      v-if="isSuperAdminPermission"
      :isOpened="isEditEmployeeOpened"
      :selectedItem="selectedEmployee"
      @update="updateHandler($event)"
      @close="closeEditEmployee"
    ></EditEmployeePopUp>
    <ConfirmPopUp
      v-if="isSuperAdminPermission"
      :question="'Вы действительно хотите удалить этого сотрудника?'"
      :isOpened="isDeleteEmployeeOpened"
      @yesAction="deleteEmployee"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp>
  </v-row>
</template>

<script>
import DetailEmployeePopUp from "../popUps/DetailEmployeePopUp.vue";
import {
  GET_ALL_ADMINS_IN_CALLCENTER_URL,
  GET_DELETE_ADMIN_URL,
} from "../../../config/http";
import EditEmployeePopUp from "../popUps/EditEmployeePopUp.vue";
import ConfirmPopUp from "../../shared/ConfirmPopUp.vue";
import appUIService from "../../../utils/appUIService";

import RoleMixin from "../../../mixins/RoleMixin";
import DataTableMixin from "../../../mixins/DataTableMixin";
import TabMixin from "../../../mixins/TabMixin";

export default {
  name: "CallCenterEmployeesTab",
  mixins: [DataTableMixin, RoleMixin, TabMixin],
  data: () => ({
    headers: [],
    selectedEmployee: null,
    isDetailEmployeeOpened: false,
    isEditEmployeeOpened: false,
    isDeleteEmployeeOpened: false,
  }),
  created() {
    this.getHeaders();
    appUIService.getInstance().employeeAddedCallback = this.addHandler;
  },
  destroyed() {
    appUIService.getInstance().employeeAddedCallback = () => {};
  },
  methods: {
    watcherHandler() {
      this.getEmployeeFromApi();
    },
    tabWatcherHandler() {
      this.getEmployeeFromApi();
    },
    getHeaders() {
      this.headers = [
        { text: "", value: "icon", sortable: false, width: 36 },
        { text: "Имя", value: "firstName", sortable: true },
        { text: "Фамилия", value: "lastName", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Роль", value: "roleId", sortable: true },
        // { text: "Команды", value: "adminTeams", sortable: false },
        { text: "", value: "edit", sortable: false, width: 36 },
        { text: "", value: "delete", sortable: false, width: 36 },
      ];
    },
    getEmployeeFromApi() {
      this.isRequestWaiting = true;

      const params = {
        ...this.getQueryParams(),
        callCenterId: this.callCenterId,
      };

      this.$http
        .get(GET_ALL_ADMINS_IN_CALLCENTER_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            this.items = data.data.items;
            this.totalItems = data.data.count;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    userClickHandler(item) {
      // console.log(item);
      this.selectedEmployee = item;
      this.isDetailEmployeeOpened = true;
    },
    openEditEmployee(employee) {
      this.selectedEmployee = employee;
      this.isEditEmployeeOpened = true;
    },
    closeEditEmployee() {
      this.isEditEmployeeOpened = false;
      this.selectedEmployee = null;
    },
    openDeletePopUp(item) {
      this.selectedEmployee = item;
      this.isDeleteEmployeeOpened = true;
    },
    closeDeletePopUp() {
      this.isDeleteEmployeeOpened = false;
      this.selectedEmployee = null;
    },
    deleteEmployee() {
      this.isRequestWaiting = true;
      this.$http
        .delete(GET_DELETE_ADMIN_URL(this.selectedEmployee.id))
        .then((response) => {
          response.json().then(() => {
            // console.log(data);
            this.deleteHandler();
            this.getEmployeeFromApi();
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    getRussianRoleName(user) {
      return this.$store.getters.roles.find((item) => item.id === user.roleId)
        .name;
    },
    setPage(event) {
      this.$store.commit("setCallCenterEmployeesPage", event.page);
      this.$store.commit("setCallCenterEmployeesPerPage", event.itemsPerPage);
    },
  },
  computed: {
    currentPage() {
      return this.$store.getters.CallCenterEmployeesPage;
    },
    currentPerPage() {
      return this.$store.getters.CallCenterEmployeesPerPage;
    },
  },
  components: {
    EditEmployeePopUp,
    ConfirmPopUp,
    DetailEmployeePopUp,
  },
  props: {
    callCenterId: Number,
  },
};
</script>

<style></style>
