<template>
  <v-dialog
    v-model="isOpened"
    max-width="1000"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-2" no-gutters>
        <v-col align="center">
          <span class="text-h6 font-weight-bold">Информация о ставках</span>
        </v-col>
      </v-row>
      <!-- <v-row class="mb-4" no-gutters>
        <v-col align="end">
          <v-btn @click="openEditBids()" color="primary">
            Повлиять на валютную пару
          </v-btn>
        </v-col>
      </v-row> -->
      <v-row v-if="itemCopy" class="bids-table-container" no-gutters>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="bids"
            :loading="isRequestWaiting"
          >
            <template v-slot:item.endPrice="{ item }">
              <!-- {{
                "Результат: " +
                (item.endPrice >= item.startPrice ? "+" : "-") +
                ((item.endPrice * item.startPrice) / 100).toFixed(5) +
                "%"
              }} -->
              {{ item.profit.toFixed(3) }}$
            </template>
            <template v-slot:item.Currency.predict="{ item }">
              {{ item.Currency.predict ? "Выше" : "Ниже" }}
            </template>
            <template v-slot:item.endDate="{ item }">
              {{ item.endDate ? getFormatDateTime(item.endDate) : "-" }}
            </template>
            <template v-slot:item.edit="{ item }">
              <v-btn
                @click="openEditBids(item)"
                color="primary"
                :disabled="item.endPrice !== null"
                small
                block
              >
                {{
                  item.endPrice !== null
                    ? `Сделка закрыта`
                    : `Повлиять на ставку`
                }}
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <EditBidsPopUp
      :isOpened="isEditPopUpOpened"
      :selectedItem="selectedBid"
      :userId="getUserId"
      :bidId="getBidId"
      @close="closeEditBids"
    ></EditBidsPopUp>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import BigNumber from "bignumber.js";
import moment from "moment";

import { GET_BIDS_USER_URL } from "../../../config/http";
import EditBidsPopUp from "./EditBidsPopUp.vue";

export default {
  name: "DetailBidsPopUp",
  mixins: [PopUpMixin, RoleMixin],
  data: () => ({
    userToken: null,
    bids: [],
    headers: [
      { text: "Валютная пара", value: "Currency.name", sortable: false },
      { text: "Результат", value: "endPrice", sortable: false },
      { text: "Дата окончания ставки", value: "endDate", sortable: false },
      { text: "Предсказание", value: "Currency.predict", sortable: false },
      { text: "", value: "edit", sortable: false },
    ],
    selectedBid: null,
    isEditPopUpOpened: false,
  }),
  methods: {
    parseBigNumber(number) {
      return new BigNumber(number).toFixed(2);
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.userToken = null;
        this.bids = [];
        this.getBidsFromApi();
      }
    },
    getBidsFromApi() {
      this.isRequestWaiting = true;
      this.$http
        .get(GET_BIDS_USER_URL(this.itemCopy.id))
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            const tagStatus = {
              succefull: {
                text: "Win",
                color: "#2eff68",
              },
              error: {
                text: "Lose",
                color: "#ff5c5c",
              },
              loading: {
                text: "loading",
                color: "white",
              },
            };
            response.data.items.forEach((element) => {
              if (element.predict == element.result) {
                element["status"] = tagStatus.succefull;
              } else {
                element["status"] = tagStatus.error;
              }
            });
            this.bids = response.data.items;

            console.log(this.bids);
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    getFormatDateTime(dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    },
    openEditBids(item = null) {
      this.selectedBid = item;
      this.isEditPopUpOpened = true;
    },
    closeEditBids() {
      this.isEditPopUpOpened = false;
      this.selectedBid = null;
    },
  },
  computed: {
    getUserId() {
      if (this.selectedItem) {
        return this.selectedItem.userId;
      }
      return null;
    },
    getBidId() {
      if (this.selectedItem) {
        return this.selectedItem.id;
      }
      return null;
    },
  },
  components: {
    EditBidsPopUp,
  },
};
</script>

<style scoped>
.bids-table-container {
  max-height: 500px;
  overflow: auto;
}
</style>
