import Vue from "vue";

export const loginKey = "login";
export const tokenKey = "token";
export const refreshTokenKey = "refreshToken";

export function RemoveAllCookies() {
  Vue.$cookies.remove(loginKey);
  Vue.$cookies.remove(tokenKey);
  Vue.$cookies.remove(refreshTokenKey);
}
