<template>
  <span>
    {{ header.text }}
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon small :color="filterQuery > 1 ? 'primary' : ''">
            mdi-filter
          </v-icon>
        </v-btn>
      </template>
      <div style="background-color: white; width: 280px">
        <v-autocomplete
          class="pl-4 pr-4"
          v-model="filterQuery"
          :items="getProcessedStatuses"
          :label="header.text"
          append-icon="mdi-close"
          item-value="id"
          @click:append="clear"
        >
          <template v-slot:selection="data">
            <v-badge
              inline
              left
              dot
              :color="processingStatusColor(data.item.id)"
            >
              {{ data.item.name }}
            </v-badge>
          </template>

          <template v-slot:item="data">
            <v-badge
              inline
              left
              dot
              :color="processingStatusColor(data.item.id)"
            >
              {{ data.item.name }}
            </v-badge>
          </template>
        </v-autocomplete>

        <v-btn block @click="submit" color="primary"> Search </v-btn>
      </div>
    </v-menu>
  </span>
</template>

<script>
import ProcessingStatusMixin from "../../mixins/ProcessingStatusMixin";
export default {
  name: "FilterProcessingStatusComponent",
  mixins: [ProcessingStatusMixin],

  props: {
    header: Object,
    isClient: Boolean,
    isLead: Boolean,
  },
  data: () => ({
    filterQuery: "",
  }),
  methods: {
    clear() {
      this.filterQuery = "";
      this.$emit("input", "");
      this.$emit("update");
    },
    submit() {
      if (this.filterQuery) {
        this.$emit("input", this.filterQuery.toString());
        this.$emit("update");
      }
    },
  },
  computed: {
    getProcessedStatuses() {
      let res = this.$store.getters.processingStatus;

      if (this.isClient)
        res = res.filter((item) => {
          return item.isClient === true;
        });
      else if (this.isLead)
        res = res.filter((item) => {
          return item.isClient === false;
        });

      return res;
    },
  },
};
</script>

<style></style>
