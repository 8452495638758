<template>
  <v-row no-gutters>
    <v-col>
      <v-row v-if="false">
        <v-col align="end">
          <v-btn @click="isCreateUserOpened = true" color="primary">
            Создать профиль
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="text-h6">Настройки</span>
        </v-col>
      </v-row>
      <v-row class="pl-5 pr-5">
        <v-col>
          <v-row align="center">
            <v-col cols="10">
              <v-select
                v-model="selectedMerchant"
                :items="DepositMerchants"
                label="Обработчик платежей"
              ></v-select>
            </v-col>
            <v-col align="left">
              <v-btn @click="saveDepositMerchant" color="primary">
                Сохранить
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="10">
              <v-text-field
                v-model="qiwiPayment"
                label="Qiwi по реквизитам"
              ></v-text-field>
            </v-col>
            <v-col align="left">
              <v-btn @click="saveQiwiPayment" color="primary">
                Сохранить
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="10">
              <v-select
                v-model="selectedDefaultPlatform"
                :items="getPlatformList"
                label="Default platform"
              ></v-select>
            </v-col>
            <v-col align="left">
              <v-btn @click="saveDefaultPlatform" color="primary">
                Сохранить
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { GET_DEFAULT_PLATFORM_URL, GET_DEPOSIT_MERCHANT_URL } from "../../../config/http";

import DataTableMixin from "../../../mixins/DataTableMixin";
import RoleMixin from "../../../mixins/RoleMixin";

export default {
  name: "SettingsTab",
  mixins: [DataTableMixin, RoleMixin],
  data: () => ({
    DepositMerchants: ["Paylama", "Betatransfer"],
    selectedMerchant: null,
    selectedDefaultPlatform: null,
    isRequestWaiting: false,
    qiwiPayment: "",
    isVerificationUserOpened: false,
    isDetailBidsPopUpOpened: false,
    isDetailActionsPopUpOpened: false,
    isEditSeveralPopUpOpened: false,
  }),
  methods: {
    saveQiwiPayment() {
      this.isRequestWaiting = true;
      const body = {
        name: "Current Qiwi Payment Link",
        valueString: this.qiwiPayment,
      };
      this.$http
        .patch(GET_DEPOSIT_MERCHANT_URL(), body)
        .then((response) => {
          response.json().then((response) => {

            this.$store.commit("setDepositMerchant", response.data);
            this.$store.commit("alert/setAlert", {
              message: "Обработчик Qiwi платежей изменился",
              color: "green",
              icon: "cash-sync",
            });
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.$store.commit("alert/setAlert", {
            message: `${e.body.error}`,
            color: "red",
            icon: "cash-sync",
          });
          this.isRequestWaiting = false;
        });
    },

    saveDepositMerchant() {
      this.isRequestWaiting = true;
      const body = {
        name: "Current Deposit Merchant",
        valueString: this.selectedMerchant,
      };
      this.$http
        .patch(GET_DEPOSIT_MERCHANT_URL(), body)
        .then((response) => {
          response.json().then((response) => {

            this.$store.commit("setDepositMerchant", response.data);
            this.$store.commit("alert/setAlert", {
              message: "Обработчик платежей изменился",
              color: "green",
              icon: "cash-sync",
            });
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.$store.commit("alert/setAlert", {
            message: `${e.body.error}`,
            color: "red",
            icon: "cash-sync",
          });
          this.isRequestWaiting = false;
        });
    },
    saveDefaultPlatform() {
      this.isRequestWaiting = true;
      const platformName = this.selectedDefaultPlatform;
      const platforms = this.$store.getters.platforms;
      const platformId = platforms.find((platform) => platform.name === platformName).id || 0;
      const body = {
        name: "Default platform",
        valueString: platformId,
      };
      this.$http
        .patch(GET_DEFAULT_PLATFORM_URL(), body)
        .then((response) => {
          response.json().then((response) => {

            this.$store.commit("setDefaultPlatform", response.data);
            this.$store.commit("alert/setAlert", {
              message: "Default platform has changed",
              color: "green",
              icon: "cash-sync",
            });
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.$store.commit("alert/setAlert", {
            message: `${e.body.error}`,
            color: "red",
            icon: "cash-sync",
          });
          this.isRequestWaiting = false;
        });
    },
  },

  computed: {
    currentDepositMerchant() {
      return this.$store.getters.depositMerchant;
    },
    getPlatformList() {
      return this.$store.getters.platforms.map((platform) => platform.name);
    },
  },
  components: {
  },
  mounted() {
    this.selectedMerchant = this.currentDepositMerchant.valueString;
    this.qiwiPayment = this.$store.getters.qiwiPayment.valueString;
    const platforms = this.$store.getters.platforms;
    const activePlatform = this.$store.getters.defaultPlatform.valueString;
    this.selectedDefaultPlatform = platforms.find((platform) => +platform.id === +activePlatform).name;
  },
};
</script>

<style></style>
