<template>
  <span>
    {{ header.text }}
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon small :color="filterQuery > 1 ? 'primary' : ''">
            mdi-filter
          </v-icon>
        </v-btn>
      </template>
      <div style="background-color: white; width: 280px">
        <v-autocomplete
          class="pl-4 pr-4"
          v-model="filterQuery"
          :items="items"
          :label="header.text"
          item-text="name"
          item-value="id"
          append-icon="mdi-close"
          @click:append="clear"
          @click="getCallCentersFromApi"
        >
          <template v-slot:item="data">
            {{ `${data.item.name}` }}
          </template>
        </v-autocomplete>

        <v-btn block @click="submit" color="primary"> Search </v-btn>
      </div>
    </v-menu>
  </span>
</template>

<script>
import { GET_ALL_CALL_CENTERS_URL } from "../../config/http";
export default {
  name: "FilterCallCentersComponent",
  props: {
    header: Object,
  },
  data: () => ({
    filterQuery: "",
    items: [],
  }),
  methods: {
    clear() {
      this.filterQuery = "";
      this.$emit("input", "");
      this.$emit("update");
    },
    getCallCentersFromApi() {
      this.isRequestWaiting = true;
      const params = {};
      this.$http
        .get(GET_ALL_CALL_CENTERS_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            this.items = data.data.items;
            this.totalItems = data.data.items.length;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    submit() {
      if (this.filterQuery) {
        this.$emit("input", this.filterQuery.toString());
        this.$emit("update");
      }
    },
  },
};
</script>

<style></style>
