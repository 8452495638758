import Vue from "vue";
import { GET_WHOAMI_URL } from "../config/http";
import { RemoveAllCookies } from "../utils/cookiesUtility";

const getDefaultState = () => {
  return {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    roleId: null,
    token: null,
    refreshToken: null,
    callCenterId: null,
    callCenter: null,
    statistics: null,
  };
};

export default {
  state: getDefaultState(),
  getters: {
    id: (state) => state.id,
    email: (state) => state.email,
    firstName: (state) => state.firstName,
    lastName: (state) => state.lastName,
    roleId: (state) => state.roleId,
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    callCenterId: (state) => state.callCenterId,
    callCenter: (state) => state.callCenter, //потом перенести в отдельный стор
    statistics: (state) => state.statistics,
  },
  mutations: {
    setUser(state, user) {
      state.id = user.id;
      state.email = user.email;
      state.firstName = user.firstName;
      state.lastName = user.lastName;
      state.roleId = user.roleId;
      state.callCenterId = user.callCenterId;
    },
    setCallCenter(state, callCenter) {
      state.callCenter = callCenter;
    },
    setStatistics(state, statistics) {
      state.statistics = statistics;
    },
    setToken(state, token) {
      state.token = token;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    logout(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    restoreSession({ commit, dispatch }, { callback, callbackFailed }) {
      Vue.http
        .get(GET_WHOAMI_URL())
        .then((response) => {
          response.json().then((response) => {
            console.log("WHOAMI:");
            console.log(response);
            commit("setUser", response.data.admin);
            commit("setCallCenter", response.data.admin.CallCenter);
            commit("setStatistics", response.data.admin.statistics);
          });
          dispatch("getConstants", { callback, callbackFailed });
        })
        .catch((e) => {
          console.error(e);
          RemoveAllCookies();
          dispatch("logout");
          if (typeof callbackFailed === "function") {
            callbackFailed();
          }
        });
    },
    trySetToken({ commit }, { token }) {
      commit("setToken", token);
    },
    trySetRefreshToken({ commit }, { refreshToken }) {
      commit("setRefreshToken", refreshToken);
    },
    logout({ commit }) {
      commit("logout");
    },
  },
};
