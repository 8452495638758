<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Изменить сотрудника </span>
        </v-col>
      </v-row>
      <v-form
        v-if="selectedItem"
        v-model="isValid"
        @submit.prevent="submitHandler"
        ref="form"
      >
        <v-row class="mb-4" no-gutters>
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'roleId', $event)"
              :value="itemCopy.roleId"
              :items="getLowerLevelRoles"
              item-text="name"
              item-value="id"
              label="Роль"
              placeholder="Роль"
              :rules="[...rules.notNull]"
              ref="role"
              @dblclick="$refs.role.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
				<v-row class="mb-4" no-gutters>
          <v-col>
            <v-text-field
              @input="updateLocalItem(itemCopy, 'postbackUrl', $event)"
              :value="itemCopy.postbackUrl"
              :items="itemCopy.postbackUrl"
              item-text="name"
              item-value="id"
              label="Postback URL"
              placeholder="URL"
              :rules="[...rules.notNull]"
              ref="role"
              @dblclick="$refs.role.blur()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import { GET_UPDATE_ADMIN_URL } from "../../../config/http";

export default {
  name: "EditEmployeePopUp",
  mixins: [PopUpMixin, RoleMixin],
  data: () => ({}),
  methods: {
    submitAction() {
      const body = {
        roleId: this.itemCopy.roleId,
				postbackUrl: this.itemCopy.postbackUrl,
      };
      this.$http
        .patch(GET_UPDATE_ADMIN_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.isRequestWaiting = false;
            this.$emit("update", this.itemCopy);
            this.$emit("close");
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
  },
};
</script>

<style scoped></style>
