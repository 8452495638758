const getDefaultState = () => {
  return {
    ProfilesMainPage: 1,
    ProfilesMainPerPage: 10,
    ProfilesVerificationPage: 1,
    ProfilesVerificationPerPage: 10,

    DepositRequestsPage: 1,
    DepositRequestsPerPage: 10,
    WithdrawRequestPage: 1,
    WithdrawRequestPerPage: 10,

    AllCallCentersPage: 1,
    AllCallCentersPerPage: 10,

    CallCenterUsersPage: 1,
    CallCenterUsersPerPage: 10,
    CallCenterLeadsPage: 1,
    CallCenterLeadsPerPage: 10,
    CallCenterClientsPage: 1,
    CallCenterClientsPerPage: 10,
    CallCenterEmployeesPage: 1,
    CallCenterEmployeesPerPage: 10,

    CallCenterNotProcessedUsersPage: 1,
    CallCenterNotProcessedUsersPerPage: 10,
    CallCenterProcessedLaterPage: 1,
    CallCenterProcessedLaterPerPage: 10,
    CallCenterProcessedUsersPage: 1,
    CallCenterProcessedUsersPerPage: 10,
  };
};

export default {
  state: getDefaultState(),
  getters: {
    ProfilesMainPage: (state) => state.ProfilesMainPage,
    ProfilesMainPerPage: (state) => state.ProfilesMainPerPage,
    ProfilesVerificationPage: (state) => state.ProfilesVerificationPage,
    ProfilesVerificationPerPage: (state) => state.ProfilesVerificationPerPage,

    DepositRequestsPage: (state) => state.DepositRequestsPage,
    DepositRequestsPerPage: (state) => state.DepositRequestsPerPage,
    WithdrawRequestPage: (state) => state.WithdrawRequestPage,
    WithdrawRequestPerPage: (state) => state.WithdrawRequestPerPage,

    AllCallCentersPage: (state) => state.AllCallCentersPage,
    AllCallCentersPerPage: (state) => state.AllCallCentersPerPage,

    CallCenterUsersPage: (state) => state.CallCenterUsersPage,
    CallCenterUsersPerPage: (state) => state.CallCenterUsersPerPage,
    CallCenterLeadsPage: (state) => state.CallCenterLeadsPage,
    CallCenterLeadsPerPage: (state) => state.CallCenterLeadsPerPage,
    CallCenterClientsPage: (state) => state.CallCenterClientsPage,
    CallCenterClientsPerPage: (state) => state.CallCenterClientsPerPage,
    CallCenterEmployeesPage: (state) => state.CallCenterEmployeesPage,
    CallCenterEmployeesPerPage: (state) => state.CallCenterEmployeesPerPage,

    CallCenterNotProcessedUsersPage: (state) =>
      state.CallCenterNotProcessedUsersPage,
    CallCenterNotProcessedUsersPerPage: (state) =>
      state.CallCenterNotProcessedUsersPerPage,
    CallCenterProcessedLaterPage: (state) => state.CallCenterProcessedLaterPage,
    CallCenterProcessedLaterPerPage: (state) =>
      state.CallCenterProcessedLaterPerPage,
    CallCenterProcessedUsersPage: (state) => state.CallCenterProcessedUsersPage,
    CallCenterProcessedUsersPerPage: (state) =>
      state.CallCenterProcessedUsersPerPage,
  },
  mutations: {
    setProfilesMainPage(state, page) {
      state.ProfilesMainPage = page;
    },
    setProfilesMainPerPage(state, perPage) {
      state.ProfilesMainPerPage = perPage;
    },
    setProfilesVerificationPage(state, page) {
      state.ProfilesVerificationPage = page;
    },
    setProfilesVerificationPerPage(state, perPage) {
      state.ProfilesVerificationPerPage = perPage;
    },

    setDepositRequestsPage(state, page) {
      state.DepositRequestsPage = page;
    },
    setDepositRequestsPerPage(state, perPage) {
      state.DepositRequestsPerPage = perPage;
    },
    setWithdrawRequestPage(state, page) {
      state.WithdrawRequestPage = page;
    },
    setWithdrawRequestPerPage(state, perPage) {
      state.WithdrawRequestPerPage = perPage;
    },

    setAllCallCentersPage(state, page) {
      state.AllCallCentersPage = page;
    },
    setAllCallCentersPerPage(state, perPage) {
      state.AllCallCentersPerPage = perPage;
    },

    setCallCenterUsersPage(state, page) {
      state.CallCenterUsersPage = page;
    },
    setCallCenterUsersPerPage(state, perPage) {
      state.CallCenterUsersPerPage = perPage;
    },
    setCallCenterLeadsPage(state, page) {
      state.CallCenterLeadsPage = page;
    },
    setCallCenterLeadsPerPage(state, perPage) {
      state.CallCenterLeadsPerPage = perPage;
    },
    setCallCenterClientsPage(state, page) {
      state.CallCenterClientsPage = page;
    },
    setCallCenterClientsPerPage(state, perPage) {
      state.CallCenterClientsPerPage = perPage;
    },
    setCallCenterEmployeesPage(state, page) {
      state.CallCenterEmployeesPage = page;
    },
    setCallCenterEmployeesPerPage(state, perPage) {
      state.CallCenterEmployeesPerPage = perPage;
    },

    setCallCenterNotProcessedUsersPage(state, page) {
      state.CallCenterNotProcessedUsersPage = page;
    },
    setCallCenterNotProcessedUsersPerPage(state, perPage) {
      state.CallCenterNotProcessedUsersPerPage = perPage;
    },
    setCallCenterProcessedLaterPage(state, page) {
      state.CallCenterProcessedLaterPage = page;
    },
    setCallCenterProcessedLaterPerPage(state, perPage) {
      state.CallCenterProcessedLaterPerPage = perPage;
    },
    setCallCenterProcessedUsersPage(state, page) {
      state.CallCenterProcessedUsersPage = page;
    },
    setCallCenterProcessedUsersPerPage(state, perPage) {
      state.CallCenterProcessedUsersPerPage = perPage;
    },

    // logout(state) {
    //   Object.assign(state, getDefaultState());
    // },
  },
  actions: {},
};
