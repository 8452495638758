<template>
  <v-row class="pa-10">
    <v-col>
      <v-card class="pa-5">
        <v-row>
          <v-col>
            <span class="text-h6">Calendar</span>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10">
            <Calendar :items="items" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10">
            <!-- <VCalendar /> -->
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- <ConfirmPopUp
      :question="'Вы действительно хотите удалить этот Колл-центр?'"
      :isOpened="isDeletePopUpOpened"
      @yesAction="deleteCallCenter"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp> -->
  </v-row>
</template>

<script>
import { GET_MY_REMINDER_URL } from "../../config/http";

import RoleMixin from "../../mixins/RoleMixin";
// import VCalendar from "../core/VCalendar.vue";
import Calendar from "../core/Calendar.vue";

export default {
  name: "CalendarPage",
  mixins: [RoleMixin],
  data: () => {
    return {
      options: {},
      items: [],
    };
  },
  methods: {
    watcherHandler() {
      this.getRemindersFromApi();
    },
    getRemindersFromApi() {
      this.isRequestWaiting = true;

      this.$http
        .get(GET_MY_REMINDER_URL())
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            this.items = data.data.items;
            // this.totalItems = data.data.items.length;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },

    openDeletePopUp(item) {
      this.selectedItem = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedItem = null;
    },
  },
  computed: {},
  components: {
    Calendar,
  },
  created() {
    this.watcherHandler();
  },

  watch: {},
};
</script>

<style scoped></style>
