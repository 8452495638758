<template>
  <v-card class="statistics-card mb-6" elevation="10">
    <v-row class="statistics-card-value-container" align="center" :wrap="false" no-gutters>
      <v-col align="center" cols="12">
        <v-icon size="50" color="primary">{{ statistics.icon }} </v-icon>
      </v-col>
      <v-col align="center" cols="12">
        <span class="text-h4 font-weight-bold">{{ statistics.value }}</span>
      </v-col>
    </v-row>
    <v-row class="statistics-card-name-container" align="center" no-gutters>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col class="pa-2" cols="12" align="center">
        <span class="text-subtitle-2">{{ statistics.name }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "StatisticsCard",
  data: () => ({}),
  methods: {},
  components: {},
  props: {
    statistics: Object,
  },
};
</script>

<style>
.statistics-card {
  height: 200px;
  width: 200px;
  border-radius: 24px !important;
}
.statistics-card-value-container {
  height: 120px;
}
.statistics-card-name-container {
  height: 80px;
}
</style>
