<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="itemCopy" no-gutters>
        <v-col>
          <v-row class="mb-2" no-gutters>
            <v-col>
              <span>
                <span class="font-weight-bold">ID сотрудника:</span>
                {{ `${itemCopy.id}` }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-2" no-gutters>
            <v-col>
              <span>
                <span class="font-weight-bold">Уникальный код сотрудника:</span>
                {{ `${itemCopy.uniqueCode}` }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-2" no-gutters>
            <v-col>
              <span>
                <span class="font-weight-bold">Email:</span>
                {{ `${itemCopy.email}` }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-2" no-gutters>
            <v-col>
              <span>
                <span class="font-weight-bold">Имя:</span>
                {{ itemCopy.firstName }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-2" no-gutters>
            <v-col>
              <span>
                <span class="font-weight-bold">Фамилия:</span>
                {{ itemCopy.lastName }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-2" no-gutters>
            <v-col>
              <span>
                <span class="font-weight-bold">Роль:</span>
                {{ getRussianRoleName(itemCopy) }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="statistics" class="mb-2" no-gutters>
            <v-col>
              <v-row
                class="mb-2"
                v-for="(item, index) in statistics"
                :key="index"
                no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">{{ `${item.name}: ` }}</span>
                    {{ item.value }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mb-2" no-gutters>
            <v-col> {{ getRefUrl() }} </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import StatisticsMixin from "../../../mixins/StatisticsMixin";

import {
  GET_ADMIN_IN_CALLCENTER_URL,
  GET_CALL_CENTER_URL,
} from "../../../config/http";

export default {
  name: "DetailEmployeePopUp",
  mixins: [PopUpMixin, RoleMixin, StatisticsMixin],
  data: () => ({
    statistics: null,
  }),
  methods: {
    getRussianRoleName(user) {
      return this.$store.getters.roles.find((item) => item.id === user.roleId)
        .name;
    },

    getRefUrl() {
      return `${process.env.VUE_APP_HOST}registration?b416c34a=${this.itemCopy.callCenterId}&c27a0712=${this.itemCopy.id}`;
    },
    getStatistics() {
      if (
        this.itemCopy.Role.level >=
        this.$store.getters.roleSuperSuperAdmin.level // set role
      ) {
        this.getCallCenterStatisticsFromApi();
      } else {
        this.getManagerStatistics();
      }
    },
    getCallCenterStatisticsFromApi() {
      if (!this.itemCopy.CallCenter) {
        return;
      }
      this.$http
        .get(GET_CALL_CENTER_URL(this.itemCopy.CallCenter.id))
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            const data = response.data.item.statistics;
            console.log(this.itemCopy);

            const statistics = this.getStatisticsByRole(this.itemCopy.roleId);

            const cards = [];
            for (var key of Object.keys(statistics)) {
              cards.push({ ...statistics[key], value: data[key] });
            }

            this.statistics = cards;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    getManagerStatistics() {
      this.$http
        .get(GET_ADMIN_IN_CALLCENTER_URL(this.itemCopy.id))
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            const data = response.data.item.statistics;

            const statistics = this.getStatisticsByRole(this.itemCopy.roleId);

            const cards = [];
            for (var key of Object.keys(statistics)) {
              cards.push({ ...statistics[key], value: data[key] });
            }

            this.statistics = cards;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.statistics = null;
        this.getStatistics();
      }
    },
  },
  computed: {
    getAdminRoles() {
      return this.$store.getters.roles;
    },
  },
};
</script>

<style scoped>
.teams-members-container {
  max-height: 400px;
  overflow: auto;
}
</style>
