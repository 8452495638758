<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row class="mb-16" no-gutters>
        <v-col>
          <UpperBar></UpperBar>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-col>
    <Reminder />
  </v-row>
</template>

<script>
import moment from "moment";
import UpperBar from "../components/core/upperBar/UpperBar.vue";
import Reminder from "../components/core/Reminder.vue";

import {
  GET_WS_REMINDER_URL,
  // GET_WSS_REMINDER_URL,
} from "../config/http";

export default {
  name: "AppPage",
  data: () => ({
    isOpenedNavigation: false,
    status: null,
    wsdata: null,
  }),
  methods: {
    openNavigation() {
      this.isOpenedNavigation = !this.isOpenedNavigation;
    },
    setReminder(time, name) {
      this.$store.commit("alert/setAlert", {
        message: `${time} ${name}`,
        color: "green",
        icon: "phone",
      });
    },
    getName(data) {
      return `${data.reminder.User.firstName} ${data.reminder.User.lastName}`;
    },
  },
  mounted() {
    console.log("Начало соединения.");
    let socket = new WebSocket(GET_WS_REMINDER_URL(this.$store.getters.token));

    socket.onopen = () => {
      console.log("Соединение установлено.");
    };

    socket.onmessage = (message) => {
      console.log("Сообщение.");
      console.log(message);
      console.log(JSON.parse(message.data));

      const data = JSON.parse(message.data);

      if (data.action == "reminder") {
        console.log("Тип = reminder.");
        this.setReminder(
          moment(data.reminder.date).format("LT"),
          this.getName(data)
        );
      }
    };
  },

  components: {
    UpperBar,
    Reminder,
  },
};
</script>

<style></style>
