<template>
  <v-dialog
      v-model="isOpened"
      :max-width="computeWidth"
      @click:outside="closePopUp"
      persistent
  >
    <v-card class="px-6 py-4">
      <v-row align="center" justify="end">
        <v-col
            v-if="
            isSuperAdminPermission ||
            iAmSaleHead ||
            iAmCRMManager ||
            iAmSupport ||
            iAmSale ||
            iAmRet ||
            iAmRetTeam ||
            iAmSaleTeam ||
            iAmRetHead ||
            iAmSaleHead
          "
            cols="auto"
            align="end"
        >
          <v-btn :disabled="!isLeadDetailsLoaded" @click="openEditPopUp" icon>
            <v-icon color="primary"> mdi-cog</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col md="12" lg="6">
          <v-row class="mb-2" no-gutters>
            <v-col align="center">
              <span class="text-h6 font-weight-bold">Профиль пользователя</span>
            </v-col>
          </v-row>
          <v-row v-if="itemCopy" no-gutters justify-md="center">
            <v-col md="9" lg="12">
              <v-row class="mb-1" no-gutters>
                <v-col>
                  <span>
                    <span class="font-weight-bold">Email:</span>
                    {{
                      ` ${itemCopy.email} (${
                          itemCopy.isEmailVerified
                              ? "Подтвержден"
                              : "Не подтвержден"
                      })`
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="mb-1" no-gutters>
                <v-col>
                  <span>
                    <span class="font-weight-bold">Имя:</span>
                    {{ ` ${itemCopy.firstName}` }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="mb-1" no-gutters>
                <v-col>
                  <span>
                    <span class="font-weight-bold">Фамилия:</span>
                    {{ ` ${itemCopy.lastName}` }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="isSuperAdminPermission || iAmSale || iAmRet"
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Страна:</span>
                    {{ ` ${itemCopy.country}` }}
                  </span>
                </v-col>
              </v-row>

              <v-row
                  v-if="isSuperAdminPermission || iAmSale || iAmRet"
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Дата регистрации:</span>
                    {{ getFormatDate(itemCopy.createdAt) }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="isSuperAdminPermission || iAmSale || iAmRet"
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">
                      Дата последней авторизации:
                    </span>
                    {{ getFormatDate(itemCopy.lastLoginDate) }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="!iAmAffTeam && !iAmAffManager"
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">
                      Платформа:
                    </span>
                    {{ itemCopy.Platform.name }}
                  </span>
                </v-col>
              </v-row>

              <v-row
                  v-if="
                  isSuperAdminPermission ||
                  iAmSale ||
                  iAmSaleTeam ||
                  iAmSaleHead ||
                  iAmSupport ||
                  iAmRet ||
                  iAmRetHead ||
                  iAmAffTeam ||
                  iAmAffManager
                "
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Номер телефона:</span>
                    {{
                      ` ${phoneNumber} ${
                          phoneStatus ? "(" + phoneStatus.name + ")" : ""
                      }`
                    }}
                       <v-btn
                           v-if="isLeadDetailsLoaded && ( !iAmAffTeam && !iAmAffManager)"
                           @click.stop="startCall(phoneNumber)"
                           color="primary"
                           icon
                           x-small
                       >
                        <v-icon>mdi-phone</v-icon>
                      </v-btn>
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="
                  itemCopy.additionalPhones.length > 0 &&
                  (isSuperAdminPermission ||
                    iAmRetTeam ||
                    iAmRetHead ||
                    iAmSaleHead)
                "
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <v-card class="pa-1 mt-0 mb-3" elevation="1">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left font-weight-bold">
                            Дополнительные телефоны
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, index) in itemCopy.additionalPhones"
                            :key="index"
                        >
                          <td>
                            {{ item }}

                            <!-- <v-text-field-simplemask
                              :value="item"
                              label=""
                              :placeholder="item.phone"
                              :properties="{
                                rules: [...rules.onlyNumber],
                                disabled: true,
                              }"
                              :options="{
                                inputMask: '+###-###-###-##-##',
                                outputMask: '#############',
                                alphanumeric: false,
                              }"
                            ></v-text-field-simplemask> -->
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card
                  >
                </v-col>
              </v-row>
              <v-row
                  v-if="isSuperAdminPermission || iAmSale || iAmRet"
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Звонили ранее:</span>
                    {{ ` ${itemCopy.isFirstCall ? "Да" : "Нет"}` }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="isSuperAdminPermission || iAmSale || iAmRet || iAmRetHead"
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Баланс:</span>
                    {{ ` ${parseBigNumber(itemCopy.balance)}` }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  class="mb-1"
                  no-gutters
                  v-if="
                  isSuperAdminPermission || iAmSale || iAmRet || iAmSaleTeam
                "
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">IP Адрес:</span>
                    {{
                      ` ${
                          itemCopy.ipAddress ? itemCopy.ipAddress : "Не присвоен"
                      }`
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="isSuperAdminPermission || iAmSale || iAmRet"
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Колл-центр:</span>
                    {{
                      ` ${
                          itemCopy.CallCenter
                              ? itemCopy.CallCenter.name
                              : "Не присвоен"
                      }`
                    }}
                  </span>
                </v-col>
              </v-row>
              <!-- <v-row
                v-if="
                  isSuperAdminPermission ||
                  iAmCRMManager ||
                  iAmSale ||
                  iAmRet ||
                  iAmSaleTeam ||
                  iAmRetHead ||
                  iAmSaleHead
                "
                class="mb-1"
                no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Команда:</span>
                    {{
                      ` ${itemCopy.Team ? itemCopy.Team.name : "Не присвоена"}`
                    }}
                  </span>
                </v-col>
              </v-row> -->
              <v-row
                  v-if="
                  isSuperAdminPermission || iAmCRMManager || iAmSaleHead || iAmRetHead
                "
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">
                      Назначенный рекламный менеджер:
                    </span>
                    {{
                      ` ${
                          itemCopy.AffManagerAdmin
                              ? `${itemCopy.AffManagerAdmin.firstName} ${itemCopy.AffManagerAdmin.lastName}`
                              : "Не присвоен"
                      }`
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="
                  isSuperAdminPermission || iAmCRMManager || iAmSale || iAmRet
                "
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">
                      Назначенный клиент-менеджер:
                    </span>
                    {{
                      ` ${
                          itemCopy.SaleAdmin
                              ? `${itemCopy.SaleAdmin.firstName} ${itemCopy.SaleAdmin.lastName} (${itemCopy.SaleAdmin.email})`
                              : "Не присвоен"
                      }`
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="
                  isSuperAdminPermission || iAmCRMManager || iAmSale || iAmRet
                "
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">
                      Назначенный финансовый консультант:
                    </span>
                    {{
                      ` ${
                          itemCopy.RetAdmin
                              ? `${itemCopy.RetAdmin.firstName} ${itemCopy.RetAdmin.lastName} (${itemCopy.RetAdmin.email})`
                              : "Не присвоен"
                      }`
                    }}
                  </span>
                </v-col>
              </v-row>

              <v-row
                  v-if="
                  isSuperAdminPermission ||
                  iAmSale ||
                  iAmRet ||
                  iAmRetHead ||
                  iAmSaleHead
                "
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Статус аккаунта:</span>
                    {{
                      ` ${
                          itemCopy.AccountStatus
                              ? itemCopy.AccountStatus.name
                              : "Не присвоен"
                      }`
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="isSuperAdminPermission || iAmSale || iAmRet"
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Статус верификации:</span>
                    {{
                      ` ${
                          itemCopy.VerificationStatus
                              ? itemCopy.VerificationStatus.name
                              : ""
                      } `
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  class="mb-1"
                  align="center"
                  no-gutters
                  v-if="
                  isSuperAdminPermission ||
                  iAmSupport ||
                  iAmSale ||
                  iAmRet ||
                  iAmRetTeam ||
                  iAmSaleTeam ||
                  iAmRetHead ||
                  iAmSaleHead
                "
              >
                <v-col class="pr-2" cols="auto">
                  <span>
                    <span class="font-weight-bold">Статус обработки:</span>

                    <v-badge
                        inline
                        left
                        dot
                        :color="
                        processingStatusColor(itemCopy.processingStatusId)
                      "
                    >
                      {{
                        renderProcessingcStatus(itemCopy.ProcessingStatus)
                      }}</v-badge
                    >
                  </span>
                </v-col>
                <v-col class="pl-2" cols="auto">
                  <v-btn
                      @click="isHistoryProcessPopUpOpened = true"
                      color="primary"
                      small
                  >
                    История статусов
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                  v-if="
                  isSuperAdminPermission ||
                  iAmSupport ||
                  iAmSale ||
                  iAmRet ||
                  iAmRetTeam ||
                  iAmRetHead ||
                  iAmSaleHead
                "
                  class="mb-1"
                  align="center"
                  no-gutters
              >
                <v-col class="pr-2" cols="auto">
                  <span>
                    <span class="font-weight-bold">Количество депозитов:</span>
                    {{ ` ${itemCopy.depositsCount}` }}
                  </span>
                </v-col>
                <v-col class="pl-2" cols="auto">
                  <v-btn
                      @click="isHistoryBalancePopUpOpened = true"
                      color="primary"
                      small
                  >
                    История депозитов
                  </v-btn>
                </v-col>
              </v-row>
              <!-- <v-row
                v-if="isSuperSuperAdminPermission"
                class="mb-1"
                align="center"
                no-gutters
              >
                <v-col class="pr-2" cols="auto">
                  <span>
                    <span class="font-weight-bold">Количество кредитов:</span>
                    {{ ` ${itemCopy.creaditsCount}` }}
                  </span>
                </v-col>
                <v-col class="pl-2" cols="auto">
                  <v-btn
                    @click="isHistoryBalancePopUpOpened = true"
                    color="primary"
                    small
                  >
                    История кредитов
                  </v-btn>
                </v-col>
              </v-row> -->
              <v-row
                  v-if="isSuperAdminPermission || iAmSale || iAmRet"
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Сумма депозитов:</span>
                    {{ ` ${parseBigNumber(itemCopy.depositsSum)}` }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                  v-if="
                  isSuperAdminPermission || iAmSale || iAmRet || iAmSaleHead
                "
                  class="mb-1"
                  no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">Комментарий:</span>
                    <!-- {{ itemCopy.comment }} -->
                    <template v-if="CommentHistory.length > 0">
                      {{ CommentHistory[0].comment }}
                    </template>
                  </span>
                </v-col>
              </v-row>

              <!-- <v-card
                class="pl-5 pr-5 mt-0"
                elevation="1"
                v-if="!iAmAffTeam && !iAmAffManager && !iAmSale"
              >
                <v-row class="pt-2" no-gutters>
                  <span class="font-weight-bold">
                    Выбор даты и времени звонка:
                  </span>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-dialog
                      ref="dateDialog"
                      v-model="isDatePickerOpened"
                      :return-value.sync="date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Дата звонка"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="isDatePickerOpened = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dateDialog.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6">
                    <v-dialog
                      ref="timeDialog"
                      v-model="isTimePickerOpened"
                      :return-value.sync="time"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="time"
                          label="Время звонка"
                          prepend-icon="mdi-clock"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker v-model="time" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="isTimePickerOpened = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.timeDialog.save(time)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row class="pt-2" no-gutters>
                  <v-btn
                    @click="signInByUser(true)"
                    color="primary"
                    :loading="isRequestWaiting"
                    block
                  >
                    call back
                  </v-btn>
                </v-row>
              </v-card> -->
              <v-row v-if="iCanGenerateEmailPrefillLink" class="mt-2" no-gutters>
                <v-col>
                  <v-btn
                      @click="generateEmailPrefillLink()"
                      color="info"
                      :loading="isRequestWaiting"
                      block
                  >
                    Generate Login link
                  </v-btn>
                  <v-alert
                      v-if="emailCopiedAlertActive"
                      border="left"
                      color="primary"
                      colored-border
                      elevation="3"
                      prominent
                      type="info"
                      transition="scroll-x-reverse-transition"
                  >
                    Email link copied
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="iCanSignInByUser" class="mt-2" no-gutters>
                <v-col>
                  <v-btn
                      @click="signInByUser(true)"
                      color="primary"
                      :loading="isRequestWaiting"
                      block
                  >
                    Скрытно вселиться в пользователя
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="iCanSignInByUser" class="mt-2" no-gutters>
                <v-col>
                  <v-btn
                      @click="signInByUser(false)"
                      color="primary"
                      :loading="isRequestWaiting"
                      block
                  >
                    Вселиться в пользователя
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="isSuperAdminPermission" class="mt-2" no-gutters>
                <v-col>
                  <v-btn
                      @click="openActions"
                      color="primary"
                      :loading="isRequestWaiting"
                      block
                  >
                    Действия пользователей
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="iCanEditUserDeals" class="mt-2" no-gutters>
                <v-col>
                  <v-btn
                      @click="openBids"
                      color="primary"
                      :loading="isRequestWaiting"
                      block
                  >
                    Особое поведение
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <template v-if="!iAmAffManager && !iAmAffTeam">
          <v-col md="12" lg="6">
            <v-row justify-md="center">
              <v-col md="9" lg="12">
                <CommentsSection
                    :itemCopy="itemCopy"
                    :CommentHistory="CommentHistory"
                    @update="getUserFromApi"
                />
                <DateTimePicker :itemCopy="itemCopy"/>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <HistoryBalancePopUp
        :isOpened="isHistoryBalancePopUpOpened"
        :selectedItem="itemCopy"
        @close="isHistoryBalancePopUpOpened = false"
    ></HistoryBalancePopUp>
    <HistoryProcessPopUp
        :isOpened="isHistoryProcessPopUpOpened"
        :selectedItem="itemCopy"
        @close="isHistoryProcessPopUpOpened = false"
    >
    </HistoryProcessPopUp>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import ProcessingStatusMixin from "../../../mixins/ProcessingStatusMixin";
import BigNumber from "bignumber.js";
import HistoryBalancePopUp from "./HistoryBalancePopUp.vue";
import HistoryProcessPopUp from "./HistoryProcessPopUp.vue";
import CommentsSection from "../../core/CommentsSection.vue";
import DateTimePicker from "../../core/DateTimePicker.vue";

import {GET_LOG_IN_AS_USER_URL, GET_USER_URL} from "../../../config/http";
import {PHONE_NOT_LOADED, startLeadCallByPhone} from "@/utils/startCall";

export default {
  name: "DetailUserPopUp",
  mixins: [PopUpMixin, RoleMixin, ProcessingStatusMixin],
  data: () => ({
    phoneStatus: null,
    phoneNumber: PHONE_NOT_LOADED,
    isLeadDetailsLoaded: false,
    isHistoryBalancePopUpOpened: false,
    isHistoryProcessPopUpOpened: false,
    currentComment: "",
    CommentHistory: [],
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        width: "20px",
      },
      {
        text: "Date",
        value: "createdAt",
        align: "start",
        width: "20px",
      },
      {
        text: "Comment",
        value: "comment",
        align: "start",
        groupable: false,
        width: "200px",
      },
      {
        text: "",
        value: "delete",
        sortable: false,
        groupable: false,
        width: 36,
      },
    ],
    isDatePickerOpened: false,
    isTimePickerOpened: false,
    date: null,
    time: null,
    emailCopiedAlertActive: false,
  }),
  methods: {
    getUserFromApi() {
      this.isRequestWaiting = true;
      this.phoneStatus = null;
      this.phoneNumber = PHONE_NOT_LOADED;
      this.isLeadDetailsLoaded = false;

      this.$http
          .get(GET_USER_URL(this.itemCopy.id))
          .then((response) => {
            response.json().then((response) => {
              // console.log(response);
              this.phoneStatus = response.data.item.PhoneStatus;
              this.phoneNumber = response.data.item.phone;
              this.CommentHistory = response.data.item.CommentHistory;
              this.isLeadDetailsLoaded = true;
            });
          })
          .catch((e) => {
            console.error(e.message);
          })
          .finally(() => {
            this.isRequestWaiting = false;
          });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = {...this.selectedItem};
        }
        this.getUserFromApi();
      }
    },
    parseBigNumber(number) {
      return new BigNumber(number).toFixed(2);
    },
    signInByUser(isHidden = false) {
      this.isRequestWaiting = true;

      const body = {
        isHidden,
      };

      this.$http
          .post(GET_LOG_IN_AS_USER_URL(this.itemCopy.id), body)
          .then((response) => {
            response.json().then((response) => {
              const url = `${this.itemCopy.Platform.domain}/adminAuth?token=${response.data.token}`;
              window.open(url, '_blank');
            });
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.isRequestWaiting = false;
          });
    },
    generateEmailPrefillLink() {
      const url = `${this.itemCopy.Platform.domain}/login?email=${this.itemCopy.email}`;

      navigator.clipboard.writeText(url);
      this.emailCopiedAlertActive = true;
      setTimeout(()=>{
        this.emailCopiedAlertActive = false;
      }, 1500);
    },
    openBids() {
      this.$emit("openBids");
    },
    openActions() {
      this.$emit("openActions");
    },
    openEditPopUp() {
      const selectedUser = {...this.selectedItem, phone: this.phoneNumber};
      this.closePopUp();
      this.$emit("openEdit", selectedUser);
    },
    startCall(phone) {
      startLeadCallByPhone(phone, this.itemCopy.id, this.$http);
    },
  },
  computed: {
    iCanSignInByUser() {
      if (
          this.isSuperAdminPermission ||
          this.iAmRet ||
          this.iAmRetTeam ||
          this.iAmRetHead ||
          this.iAmRet
      ) {
        return true;
      }
      return false;
    },
    iCanGenerateEmailPrefillLink() {
      if (
          this.isSuperAdminPermission ||
          this.iAmSale ||
          this.iAmSaleTeam ||
          this.iAmSaleHead
      ) {
        return true;
      }
      return false;
    },
    iCanEditUserDeals() {
      if (this.isSuperAdminPermission || this.iAmRetTeam || this.iAmRetHead || this.iAmRet) {
        return true;
      }
      return false;
    },
    computeWidth() {
      if (!this.iAmAffManager && !this.iAmAffTeam) return 1000;
      else return 800;
    },
  },
  components: {
    HistoryBalancePopUp,
    HistoryProcessPopUp,
    CommentsSection,
    DateTimePicker,
  },
};
</script>

<style scoped></style>
