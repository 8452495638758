<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Создать профиль </span>
        </v-col>
      </v-row>
      <v-form v-model="isValid" @submit.prevent="submitHandler" ref="form">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="email"
              type="email"
              label="Email"
              placeholder="Email"
              :rules="[...rules.required, ...rules.noSpaces, ...rules.email]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="firstName"
              label="Имя"
              placeholder="Имя"
              :rules="[...rules.required, ...rules.noSpaces]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="lastName"
              label="Фамилия"
              placeholder="Фамилия"
              :rules="[...rules.required, ...rules.noSpaces]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-autocomplete
              v-model="selectedCountry"
              :items="getCountries"
              item-text="name"
              item-value="code"
              label="Страна"
              placeholder="Страна"
              :rules="[...rules.notNull]"
              ref="country"
              @dblclick="$refs.country.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field-simplemask
              v-model="phone"
              label="Номер телефона"
              placeholder="Номер телефона"
              type="number"
              :properties="{
                rules: [...rules.required, ...rules.onlyNumber],
              }"
              :options="{
                inputMask: '+###-###-###-##-##',
                outputMask: '#############',
                alphanumeric: false,
              }"
            ></v-text-field-simplemask>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="ipAddress"
              label="IP Address"
              placeholder="IP Address"
              :rules="[...rules.required, ...rules.noSpaces]"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col>
            <v-autocomplete
              v-model="selectedTeam"
              :items="teams"
              item-text="name"
              item-value="id"
              label="Команда"
              placeholder="Команда"
              :loading="isRequestTeamsWaiting"
              ref="team"
              @dblclick="$refs.team.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-col>
            <v-autocomplete
              v-model="selectedManager"
              :items="managers"
              :item-text="
                (item) => `${item.firstName} ${item.lastName} (${item.email})`
              "
              item-value="id"
              label="Менеджер по рекламе"
              placeholder="Менеджер по рекламе"
              :loading="isRequestManagerWaiting"
              ref="adverstingManager"
              @dblclick="$refs.adverstingManager.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Создать
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";

import {
  GET_INSERT_USER_URL,
  GET_ALL_ADMINS_IN_CALLCENTER_URL,
  GET_TEAMS_IN_CALLCENTER_URL,
  GET_USER_URL,
} from "../../../config/http";
import appUIService from "../../../utils/appUIService";

export default {
  name: "CreateUserPopUp",
  mixins: [PopUpMixin, RoleMixin],
  data: () => ({
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    selectedCountry: null,
    selectedTeam: null,
    selectedManager: null,
    ipAddress: "",
    teams: [],
    callCenters: [],
    managers: [],
    isRequestCallCentersWaiting: false,
    isRequestTeamsWaiting: false,
    isRequestManagerWaiting: false,
  }),
  methods: {
    submitAction() {
      const body = {
        email: this.email,
        phone: this.phone,
        firstName: this.firstName,
        lastName: this.lastName,
        country: this.selectedCountry,
        ipAddress: this.ipAddress,
        callCenterId: this.callCenterId,
        teamId: this.selectedTeam,
        AffManagerAdminId: this.selectedManager,
      };
      this.$http
        .post(GET_INSERT_USER_URL(), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.getUserFromApi(response.data.user.id);
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.getManagersFromApi();
        this.getTeamsFromApi();
        this.$nextTick(() => {
          this.$refs.form?.reset();
        });
      }
    },
    getManagersFromApi() {
      this.isRequestManagerWaiting = true;

      const params = {
        callCenterId: this.callCenterId,
        roleId: this.getSuperAdminRole.id,
      };

      this.$http
        .get(GET_ALL_ADMINS_IN_CALLCENTER_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.managers = data.data.items;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestManagerWaiting = false;
        });
    },
    getTeamsFromApi() {
      this.isRequestTeamsWaiting = true;

      const params = {
        callCenterId: this.callCenterId,
      };

      this.$http
        .get(GET_TEAMS_IN_CALLCENTER_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.teams = data.data.items;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestTeamsWaiting = false;
        });
    },
    getUserFromApi(userId) {
      this.$http
        .get(GET_USER_URL(userId))
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.$emit("add", response.data.item);
            appUIService.getInstance().userAddedCallback(response.data.item);
            this.$emit("close");
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
  },
  computed: {
    getCountries() {
      return this.$store.getters.countries;
    },
  },
  props: {
    callCenterId: Number,
  },
};
</script>

<style scoped></style>
