<template>
  <div>
    <Calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="events"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <p
              v-for="attr in attributes"
              :key="attr.key"
              class="text-xs leading-tight rounded-sm pa-1 mt-0 mb-1"
              :class="attr.customData.class"
            >
              {{ attr.customData.title }}
            </p>
          </div>
        </div>
      </template>
    </Calendar>
  </div>
</template>

<script>
import moment from "moment";
// import Vue from "vue";
import Calendar from "v-calendar/lib/components/calendar.umd";
import { GET_MY_REMINDER_URL } from "../../config/http";

// Vue.component("Vcalendar", Calendar);
import RoleMixin from "../../mixins/RoleMixin";
// import VCalendar from "../core/VCalendar.vue"

export default {
  name: "CalendarPage",
  mixins: [RoleMixin],
  props: {
    items: Array,
  },
  data: () => {
    return {
      masks: {
        weekdays: "WWW",
      },
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],

      events: [],
    };
  },
  methods: {
    getRemindersFromApi() {
      this.isRequestWaiting = true;

      this.$http
        .get(GET_MY_REMINDER_URL())
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            const items = data.data.items;
            const events = [];

            items.map((item, index) => {
              events.push(this.formatArray(item, index));
            });

            this.events = events;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    formatArray(item, index) {
      return {
        key: index,
        customData: {
          title: `${moment(item.date).format("HH:mm")} ${item.User.firstName} ${
            item.User.lastName
          }`,
          class: `${
            this.colors[this.rnd(0, this.colors.length - 1)]
          } white--text`,
        },
        dates: new Date(item.date),
      };
    },

    openDeletePopUp(item) {
      this.selectedItem = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedItem = null;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  computed: {},
  components: {
    Calendar,
  },
  mounted() {
    this.getRemindersFromApi();
  },
};
</script>

<style>
.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}
.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.custom-calendar.vc-container .vc-weeks {
  padding: 0;
}
.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
.custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;
}
.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
.custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: var(--day-border-highlight);
}
.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}
.custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}

.z-10 {
  z-index: 10;
}
.overflow-hidden {
  overflow: hidden;
}
.h-full {
  height: 100%;
}
.flex-col {
  flex-direction: column;
}

.text-gray-900 {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity));
}
.text-sm {
  font-size: 0.875rem;
}

.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.flex-grow {
  flex-grow: 1;
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}
.p-1 {
  padding: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-0 {
  margin-top: 0;
}
.leading-tight {
  line-height: 1.25;
}
.text-xs {
  font-size: 0.75rem;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.bg-red-600 {
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity));
}
div.custom-calendar.max-w-full.vc-container.vc-blue.vc-is-expanded
  div.vc-pane-container
  div.vc-pane-layout
  div.vc-pane.row-from-end-1.column-from-end-1
  div.vc-weeks
  div.vc-day.in-month
  div.flex.flex-col.h-full.z-10.overflow-hidden
  div.flex-grow.overflow-y-auto.overflow-x-auto {
  overflow-y: scroll;
  max-height: 65px;
}
div.custom-calendar.max-w-full.vc-container.vc-blue.vc-is-expanded
  div.vc-pane-container
  div.vc-pane-layout
  div.vc-pane.row-from-end-1.column-from-end-1
  div.vc-weeks
  div.vc-day.in-month
  div.flex.flex-col.h-full.z-10.overflow-hidden
  span.day-label.text-sm.text-gray-900 {
  background: transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
}
div.custom-calendar.max-w-full.vc-container.vc-blue.vc-is-expanded
  div.vc-pane-container
  div.vc-pane-layout
  div.vc-pane.row-from-end-1.column-from-end-1
  div.vc-weeks
  div.vc-day.is-today.in-month
  div.flex.flex-col.h-full.z-10.overflow-hidden
  span.day-label.text-sm.text-gray-900 {
  background: #5630e0;
  color: #fff;
}
</style>
