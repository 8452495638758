var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"clickable-table",attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.currentPage,"items-per-page":_vm.currentPerPage,"loading":_vm.isRequestWaiting,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
            'items-per-page-options': _vm.rowsPerPageItems,
          }},on:{"click:row":_vm.userClickHandler,"update:options":function($event){_vm.options=$event},"pagination":_vm.setPage},scopedSlots:_vm._u([(_vm.isSuperAdminPermission)?{key:"item.icon",fn:function(ref){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")])]}}:null,{key:"item.roleId",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRussianRoleName(item))+" ")]}},(_vm.isSuperAdminPermission)?{key:"item.edit",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openEditEmployee(item)}}},[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}:null,(_vm.isSuperAdminPermission)?{key:"item.delete",fn:function(ref){
          var item = ref.item;
return [(_vm.isSuperAdminPermission)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"red","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDeletePopUp(item)}}},[_c('v-icon',[_vm._v("mdi-account-remove")])],1):_vm._e()]}}:null],null,true)})],1)],1)],1),_c('DetailEmployeePopUp',{attrs:{"isOpened":_vm.isDetailEmployeeOpened,"selectedItem":_vm.selectedEmployee},on:{"close":function($event){_vm.isDetailEmployeeOpened = false}}}),(_vm.isSuperAdminPermission)?_c('EditEmployeePopUp',{attrs:{"isOpened":_vm.isEditEmployeeOpened,"selectedItem":_vm.selectedEmployee},on:{"update":function($event){return _vm.updateHandler($event)},"close":_vm.closeEditEmployee}}):_vm._e(),(_vm.isSuperAdminPermission)?_c('ConfirmPopUp',{attrs:{"question":'Вы действительно хотите удалить этого сотрудника?',"isOpened":_vm.isDeleteEmployeeOpened},on:{"yesAction":_vm.deleteEmployee,"noAction":_vm.closeDeletePopUp,"close":_vm.closeDeletePopUp}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }