/*eslint-disable*/
import Vue from "vue";
import store from "../plugins/vuex";
import VueRouter from "vue-router";

import {
  tokenKey,
  refreshTokenKey,
  RemoveAllCookies,
} from "../utils/cookiesUtility";

import AuthPage from "../views/AuthPage.vue";
import AppPage from "../views/AppPage.vue";
import ProfilesPage from "../components/appPage/ProfilesPage.vue";
import DocumentsPage from "../components/appPage/DocumentsPage.vue";
import CallCenterPage from "../components/appPage/CallCenterPage.vue";
import AllCallCentersPage from "../components/appPage/AllCallCentersPage.vue";
import CalendarPage from "../components/appPage/CalendarPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/auth",
  },
  {
    path: "/auth",
    name: "AuthPage",
    component: AuthPage,
    meta: { notRequiresAuth: true },
  },
  {
    path: "/app",
    name: "AppPage",
    component: AppPage,
    meta: { notRequiresAuth: false },
    children: [
      {
        path: "profiles",
        name: "ProfilesPage",
        component: ProfilesPage,
        meta: { accessLevel: 4 },
      },
      {
        path: "documents",
        name: "DocumentsPage",
        component: DocumentsPage,
        meta: { accessLevel: 4 },
      },
      {
        path: "all-call-centers",
        name: "AllCallCentersPage",
        component: AllCallCentersPage,
        meta: { accessLevel: 4 },
      },
      {
        path: "call-center/:callCenterId/:tab?",
        name: "CallCenterPage",
        component: CallCenterPage,
        meta: { accessLevel: 1 },
      },
      {
        path: "calendar",
        name: "CalendarPage",
        component: CalendarPage,
        meta: { accessLevel: 2 },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const saveTokenToStore = () => {
  const token = Vue.$cookies.get(tokenKey);
  store.dispatch("trySetToken", { token });
};

const saveRefreshTokenToStore = () => {
  const refreshToken = Vue.$cookies.get(refreshTokenKey);
  store.dispatch("trySetRefreshToken", { refreshToken });
};

const isAuthProtectedRoute = (to) => {
  return to.matched.some((record) => record.meta.notRequiresAuth === false);
};

const sendWhoamiUserRequest = (to, next) => {
  store.dispatch("restoreSession", {
    callback: () => {
      customNext(to, next);
    },
    callbackFailed: () => {
      toAuthPage(next);
    },
  });
};

const toMainPage = (next) => {
  const myRoleId = store.getters.roleId;

  // const superAdminId = store.getters.roleSuperAdmin.id;
  // const adminId = store.getters.roleAdminCallCenter.id;
  // const clientManagerId = store.getters.roleClientsManager.id;
  // const financialManagerId = store.getters.roleFinancialManager.id;
  // const advertisingManagerId = store.getters.roleAdvertisingManager.id;
  // const teamAdminId = store.getters.roleTeamAdmin.id;
  // const callCenterId = store.getters.callCenterId;

  const superAdminId = store.getters.roleSuperAdmin.id;
  const CRMManagerId = store.getters.roleCRMManager.id;
  const saleId = store.getters.roleSale.id;
  const retId = store.getters.roleRet.id;
  const supportId = store.getters.roleSupport.id;
  const affManagerId = store.getters.roleAffManager.id;
  const affTeamId = store.getters.roleAffTeam.id;
  const retTeamId = store.getters.roleRetTeam.id;
  const saleTeamId = store.getters.roleSaleTeam.id;
  const saleHeadId = store.getters.roleSaleHead.id;
  const superSuperAdminId = store.getters.roleSuperSuperAdmin.id;

  switch (myRoleId) {
    case superAdminId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;

    case CRMManagerId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;

    case saleId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;

    case retId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;

    case supportId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;

    case affManagerId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;

    case affTeamId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;
    case retTeamId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;
    case saleTeamId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;
    case saleHeadId:
      next({
        name: "CallCenterPage",
        params: {
          callCenterId,
        },
      });
      break;
    case superSuperAdminId:
      next({
        name: "ProfilesPage",
        params: {},
      });
      break;
    default:
      RemoveAllCookies();
      store.dispatch("logout");
      next("/auth");
      break;
  }

  // if (myRoleId === superAdminId) {
  //   next("/app/profiles");
  // } else if (myRoleId === adminId) {
  //   next({
  //     name: "CallCenterPage",
  //     params: {
  //       callCenterId,
  //     },
  //   });
  // } else if (myRoleId === clientManagerId) {
  //   next({
  //     name: "CallCenterPage",
  //     params: {
  //       callCenterId,
  //     },
  //   });
  // } else if (myRoleId === financialManagerId) {
  //   next({
  //     name: "CallCenterPage",
  //     params: {
  //       callCenterId,
  //     },
  //   });
  // } else if (myRoleId === advertisingManagerId) {
  //   next({
  //     name: "CallCenterPage",
  //     params: {
  //       callCenterId,
  //     },
  //   });
  // } else if (myRoleId === teamAdminId) {
  //   next({
  //     name: "CallCenterPage",
  //     params: {
  //       callCenterId,
  //     },
  //   });
  // } else {
  //   RemoveAllCookies();
  //   store.dispatch("logout");
  //   next("/auth");
  // }
};

const toAuthPage = (next) => {
  next("/auth");
};

const customNext = (to, next) => {
  const myLevelAccess = store.getters.roles.find(
    (item) => item.id === store.getters.roleId
  ).level;
  const isHaveAccess = to.matched.some(
    (record) => record.meta.accessLevel <= myLevelAccess
  );
  if (isHaveAccess) {
    next();
  } else {
    toMainPage(next);
  }
};

router.beforeEach((to, from, next) => {
  const isAuth = store.getters.token !== null;
  const isToken = Vue.$cookies.get(tokenKey) !== null;
  //console.log(isAuth);
  //console.log(isToken);

  if (isAuth && isAuthProtectedRoute(to)) {
    //const superAdminLevel = store.getters.roleSuperAdmin.level;
    //const adminLevel = store.getters.roleAdminCallCenter.level;
    //const managerLevel = store.getters.roleClientsManager.level;
    //const advertisingManagerLevel = store.getters.roleAdvertisingManager.level;
    //const teamAdminLevel = store.getters.roleTeamAdmin.level;

    customNext(to, next);
  } else if (!isAuth && !isAuthProtectedRoute(to) && !isToken) {
    next();
  } else {
    if (isToken) {
      saveTokenToStore();
      sendWhoamiUserRequest(to, next);
    } else {
      toAuthPage(next);
    }
  }
});

export default router;
