<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="card-container-pop-up px-6 py-4">
      <v-row class="mb-5">
        <v-col align="start">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6">
            Изменить документы пользователя {{ selectedItem.email }}
          </span>
        </v-col>
      </v-row>

      <v-row v-if="!documents || documents.length === 0">
        <v-col align="center">
          <span>Нет документов </span>
        </v-col>
      </v-row>
      <v-row class="mt-4" no-gutters>
        <v-col class="pa-1" cols="12">
          <v-btn color="primary" @click="isUploadDocumentOpened = true" block>
            Загрузить новый документ
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-for="(item, index) in documents" :key="index">
        <v-col>
          <v-img width="100%" :src="item.url">
            <v-row align="end" justify="space-between">
              <v-col class="py-0">
                <v-list-item color="rgba(0, 0, 0, .4)" :dark="false">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col align="end" cols="auto" class="mr-5">
                <v-btn @click="() => openDocumentHandler(item.url)">
                  <v-icon color="black"> mdi-open </v-icon>
                  Открыть
                </v-btn>
                <v-btn @click="() => openDeletePopUp(index)">
                  <v-icon color="error"> mdi-delete </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-img>
        </v-col>
      </v-row>
    </v-card>
    <UploadDocumentPopup
      :isOpened="isUploadDocumentOpened"
      :selectedItem="itemCopy"
      @uploaded="handleUploaded($event)"
      @close="closeUploadDocument"
    ></UploadDocumentPopup>
    <ConfirmPopUp
      question="Вы действительно хотите удалить этот документ?"
      :isOpened="isDeleteDocumentOpened"
      @yesAction="deleteDocumentHandler"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp>
  </v-dialog>
</template>

<script>
import BigNumber from "bignumber.js";
import RoleMixin from "../../../mixins/RoleMixin";
import PopUpMixin from "../../../mixins/PopUpMixin";
import { GET_DELETE_DOCUMENT_URL } from "../../../config/http";

import UploadDocumentPopup from "./UploadDocumentPopup.vue";
import ConfirmPopUp from "../../shared/ConfirmPopUp.vue";

export default {
  name: "EditUserPopUp",
  mixins: [PopUpMixin, RoleMixin],
  data: () => ({
    isEditDocumentsOpened: false,
    isUploadDocumentOpened: false,
    isDeleteDocumentOpened: false,
    selectedDocument: null,
    documents: [],
  }),
  methods: {
    updateBalanceHandler(balance) {
      this.itemCopy.balance = balance;
      this.$emit("update", this.itemCopy);
    },
    parseBigNumber(number) {
      return new BigNumber(number).toFixed(2);
    },
    openDeletePopUp(index) {
      this.selectedDocument = { index };
      this.isDeleteDocumentOpened = true;
    },
    closeDeletePopUp() {
      this.isDeleteDocumentOpened = false;
      this.selectedDocument = null;
    },
    openDocumentHandler(url) {
      window.open(url, "_blank").focus();
    },
    closeUploadDocument() {
      this.isUploadDocumentOpened = false;
      this.selectedDocument = null;
    },
    patchUser(array) {
      const body = {
        documents: array,
      };
      this.$http
        .patch(GET_DELETE_DOCUMENT_URL(this.selectedItem.id), body)
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.response = data.data;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e);
          this.isRequestWaiting = false;
        });
    },

    deleteDocumentHandler() {
      let documents = [];
      this.documents.splice(this.selectedDocument.index, 1);
      this.documents.map((item) => {
        documents.push(item.url);
      });
      this.isDeleteDocumentOpened = false;
      this.selectedDocument = null;
      this.isRequestWaiting = true;

      // this.patchUser(documents);
      this.$emit("patchUser", documents);
    },
    handleUploaded(e) {
      let documents = [];
      console.log(this.documents);
      this.documents.push(this.createDocument(e));
      this.documents.map((item) => {
        documents.push(item.url);
      });
      this.isUploadDocumentOpened = false;
      this.selectedDocument = null;
      this.isRequestWaiting = true;

      // this.patchUser(documents);
      this.$emit("patchUser", documents);
    },
    createDocument(item) {
      let name = item.includes("___")
        ? item.slice(item.lastIndexOf("___") + 3)
        : item;
      let type = item.slice(item.lastIndexOf(".") + 1);
      return {
        name: name,
        type: type,
        url: item,
      };
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.$nextTick(() => {
          this.$refs.form?.reset();
        });

        try {
          let documents = [];
          this.itemCopy.documents.map((item, index) => {
            documents.push(this.createDocument(item, index));
          });

          this.documents = documents;
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  computed: {
    getCountries() {
      return this.$store.getters.countries;
    },
    getProcessedStatuses() {
      return this.$store.getters.processingStatus;
    },
    getPhoneStatuses() {
      return this.$store.getters.phoneStatus.map((item) => {
        item["disabled"] = item.id === 3 || item.id === 4 ? true : false;
        return item;
      });
    },
    getAccountStatuses() {
      return this.$store.getters.accountStatus;
    },
  },
  components: {
    UploadDocumentPopup,
    ConfirmPopUp,
  },
};
</script>

<style scoped></style>
