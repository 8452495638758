export default {
  name: "HeadersMixin",
  data: () => ({}),
  methods: {
    getUsersHeaders() {
      if (this.iAmCRMManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSale) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
        ];
      } else if (this.iAmRet) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSupport) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmRetTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleHead) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      }
    },
    getLeadsHeaders() {
      if (this.iAmCRMManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSale) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          // {
          //   text: "Количество депозитов",
          //   value: "depositsCount",
          //   sortable: true,
          // },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
        ];
      } else if (this.iAmRet) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
          // { text: "", value: "call", sortable: false, width: 25 },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSupport) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
          // { text: "", value: "call", sortable: false, width: 25 },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffHead) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmRetTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
          // { text: "", value: "call", sortable: false, width: 25 },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmRetHead) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
          // { text: "", value: "call", sortable: false, width: 25 },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          // { text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          // {
          //   text: "Финансовый консультант",
          //   value: "RetAdmin.firstName",
          //   sortable: true,
          // },
          // {
          //   text: "Количество депозитов",
          //   value: "depositsCount",
          //   sortable: true,
          // },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
          // { text: "", value: "call", sortable: false, width: 25 },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleHead) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
          // { text: "", value: "call", sortable: false, width: 25 },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.isSuperAdminPermission) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },

          { text: "", value: "call", sortable: false, width: 25 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Последняя дата звонка",
            value: "CallHistory",
            sortable: true,
          },
          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          // { text: "", value: "call", sortable: false, width: 25 },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      }
    },
    getClientsHeaders() {
      if (this.iAmCRMManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSale) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          // { text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
        ];
      } else if (this.iAmRet) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSupport) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmRetTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmRetHead) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },
          
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          // { text: "Команда", value: "Team.name", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          // {
          //   text: "Финансовый консультант",
          //   value: "RetAdmin.firstName",
          //   sortable: true,
          // },
          // {
          //   text: "Количество депозитов",
          //   value: "depositsCount",
          //   sortable: true,
          // },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleHead) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Дата регистрации", value: "createdAt", sortable: true },
          { text: "Дата последнего входа", value: "updatedAt", sortable: true },

          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          {
            text: "$source",
            value: "utm_source",
            sortable: true,
          },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          {
            text: "$medium",
            value: "utm_medium",
            sortable: true,
          },
          {
            text: "$campaing",
            value: "utm_campaing",
            sortable: true,
          },
          {
            text: "$content",
            value: "utm_content",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      }
    },
    getNotProcessedUsers() {
      if (this.iAmRetAdmin) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Пользователь онлайн",
            value: "isOnline",
            sortable: true,
          },
          {
            text: "Пользователь на трейдинге",
            value: "isOnTrading",
            sortable: true,
          },
          {
            text: "Дата последнего визита",
            value: "lastLoginDate",
            sortable: true,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          {
            text: "Последний комментарий",
            value: "LastComment",
            sortable: false,
            width: "350px",
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },

          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      }
    },
    getProcessedLaterHeaders() {
      if (this.iAmCRMManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },

          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSale) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          // { text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
        ];
      } else if (this.iAmRet) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSupport) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmRetTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleHead) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },

          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      }
    },
    getProcessedHeaders() {
      if (this.iAmCRMManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },

          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSale) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          // //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          // //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          // { text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
        ];
      } else if (this.iAmRet) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSupport) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffManager) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmAffTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmRetTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleTeam) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else if (this.iAmSaleHead) {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Рекламный менеджер",
            value: "AffManagerAdmin.firstName",
            sortable: true,
          },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      } else {
        this.headers = [
          { text: "", value: "icon", sortable: false, width: 36 },
          { text: "Email", value: "email", sortable: true },
          { text: "Имя", value: "firstName", sortable: true },
          { text: "Фамилия", value: "lastName", sortable: true },
          { text: "Страна", value: "country", sortable: true },
          { text: "Номер телефона", value: "phone", sortable: true },
          //{ text: "Звонили ранее", value: "isFirstCall", sortable: true },
          { text: "Баланс", value: "balance", sortable: true, width: 100 },
          {
            text: "Статус обработки",
            value: "processingStatusId",
            sortable: true,
          },
          //{ text: "IP Адрес", value: "ipAddress", sortable: true },
          { text: "Колл-центр", value: "CallCenter.name", sortable: true },
          //{ text: "Команда", value: "Team.name", sortable: true },
          {
            text: "Клиент-менеджер",
            value: "SaleAdmin.firstName",
            sortable: true,
          },
          {
            text: "Финансовый консультант",
            value: "RetAdmin.firstName",
            sortable: true,
          },
          {
            text: "Количество депозитов",
            value: "depositsCount",
            sortable: true,
          },
          { text: "Платформа", value: "Platform.name", sortable: true },
          { text: "Сумма депозитов", value: "depositsSum", sortable: true },

          {
            text: "Верификация",
            value: "verificationStatusId",
            sortable: false,
          },
          { text: "", value: "edit", sortable: false, width: 36 },
        ];
      }
    },
  },
};
