<template>
  <v-row class="pa-10" align="center" justify="space-around">
    <v-col
      v-for="(card, index) in cards"
      :key="index"
      align="center"
      sm="6"
      md="4"
      lg="2"
      xl="2"
    >
      <StatisticsCard :statistics="card"></StatisticsCard>
    </v-col>
  </v-row>
</template>

<script>
import StatisticsCard from "../cards/StatisticsCard.vue";
import RoleMixin from "../../../mixins/RoleMixin";
import TabMixin from "../../../mixins/TabMixin";
import StatisticsMixin from "../../../mixins/StatisticsMixin";

import { GET_CALL_CENTER_URL, GET_WHOAMI_URL } from "../../../config/http";

export default {
  name: "CallCenterStatisticsTab",
  mixins: [RoleMixin, TabMixin, StatisticsMixin],
  data: () => ({
    cards: [],
  }),
  created() {
    this.getStatistics();
  },
  methods: {
    tabWatcherHandler() {
      this.getStatistics();
    },
    getStatistics() {
      if (this.isSuperAdminPermission) {
        this.getCallCenterStatisticsFromApi();
      } else {
        this.getMyStatisticsCard();
      }
    },
    getCallCenterStatisticsFromApi() {
      this.$http
        .get(GET_CALL_CENTER_URL(this.callCenterId))
        .then((response) => {
          response.json().then((response) => {
            // console.log(response);
            const data = response.data.item.statistics;

            const statistics = {
              usersCount: {
                name: "Количество приведенных пользователей",
                icon: "mdi-account-group",
              },
              processedUsersCount: {
                name: "Количество обработанных аккаунтов",
                icon: "mdi-account-multiple-check",
              },
              unprocessedUsersCount: {
                name: "Количество необработанных аккаунтов",
                icon: "mdi-account-multiple-remove",
              },
              usersFirstDepositsSum: {
                name: "Сумма первых депозитов всех пользователей",
                icon: "mdi-cash-fast",
              },
              usersAllDepositsSum: {
                name: "Сумма всех депозитов пользователей",
                icon: "mdi-cash-multiple",
              },
            };

            const cards = [];
            for (var key of Object.keys(statistics)) {
              cards.push({ ...statistics[key], value: data[key] });
            }

            this.cards = cards;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    getMyStatisticsCard() {
      this.$http
        .get(GET_WHOAMI_URL())
        .then((response) => {
          response.json().then((response) => {
            // console.log(response);
            const data = response.data.admin.statistics;

            const statistics = this.getMyStatistics();

            const cards = [];
            for (var key of Object.keys(statistics)) {
              cards.push({ ...statistics[key], value: data[key] });
            }

            this.cards = cards;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
  },
  components: {
    StatisticsCard,
  },
  props: {
    callCenterId: Number,
  },
};
</script>

<style></style>
