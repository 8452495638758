<template>
  <v-card class="pa-5 mt-5" elevation="1">
    <v-row class="pt-0" no-gutters>
      <span class="font-weight-bold"> Выбор даты и времени звонка: </span>
    </v-row>

    <DatePicker
      mode="dateTime"
      v-model="date"
      :timezone="timezone"
      :valid-hours="{ min: 0, max: 24 }"
      :minute-increment="1"
      :min-date="new Date()"
      is-expanded
      is24hr
      trim-weeks
    />
    <v-row class="pt-0" no-gutters>
      <v-col>
        <v-btn
          @click="openSavePopUp"
          :loading="isRequestWaiting"
          color="primary"
          block
        >
          Сохранить
        </v-btn>
      </v-col>
    </v-row>

    <ConfirmPopUp
      :question="formatPopupText"
      :isOpened="isSavePopUpOpened"
      @yesAction="saveDateTime"
      @noAction="closeSavePopUp"
      @close="closeSavePopUp"
    ></ConfirmPopUp>
  </v-card>
</template>

<script>
// import Vue from "vue";
import moment from "moment";
// Vue.component("date-picker", DatePicker);
import RoleMixin from "../../mixins/RoleMixin";
import { GET_CREATE_REMINDER_URL } from "../../config/http";

import ConfirmPopUp from "../shared/ConfirmPopUp.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  name: "DateTimePicker",
  mixins: [RoleMixin],
  props: {
    itemCopy: Object || null || undefined,
  },
  data: () => {
    return {
      date: new Date(),
      timezone: null,
      isSavePopUpOpened: false,
      isRequestWaiting: false,
      alert: false,
      snackbar: false,
      selectedItem: null,
    };
  },
  methods: {
    openSavePopUp() {
      this.isSavePopUpOpened = true;
    },
    closeSavePopUp() {
      this.isSavePopUpOpened = false;
    },
    saveDateTime() {
      this.isRequestWaiting = true;

      // console.log(this.date);
      const body = {
        date: this.date,
        comment: "",
        userId: this.itemCopy.id,
      };
      this.$http
        .post(GET_CREATE_REMINDER_URL(), body)
        .then((response) => {
          response.json().then(() => {
            // console.log(response);
            this.isSavePopUpOpened = false;
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
  },
  computed: {
    formatPopupText() {
      return `Вы действительно хотите установить дату ${moment(
        this.date
      ).format("MMM Do")} ${moment(this.date).format("LT")}?`;
    },
    formatReminderText() {
      return `${moment(new Date()).format("LT")} First Last `;
    },
  },
  components: {
    ConfirmPopUp,
    DatePicker,
  },
};
</script>

<style scoped></style>
