<template>
  <v-dialog
    v-model="isOpened"
    max-width="400"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-2" no-gutters>
        <v-col align="center">
          <span class="text-h6 font-weight-bold">
            Повлиять на валютную пару
          </span>
        </v-col>
      </v-row>
      <v-form v-model="isValid" @submit.prevent="submitHandler" ref="form">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="price"
              type="number"
              label="Цена"
              placeholder="Цена"
              :rules="[...rules.positiveNumber]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="count"
              type="number"
              label="Количество"
              placeholder="Количество"
              :rules="[...rules.positiveNumber]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" type="submit" block>Повлиять</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="mb-2" no-gutters>
        <v-col align="center"> </v-col>
      </v-row>
      <v-row class="mb-2" no-gutters>
        <v-col align="center">
          <span class="text-h6 font-weight-bold"> Проверить профит </span>
        </v-col>
      </v-row>
      <v-form v-model="isValid" @submit.prevent="submitPredict" ref="form">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="predictPrice"
              type="number"
              label="Цена"
              placeholder="Цена"
              :rules="[...rules.positiveNumber]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="predictCount"
              type="number"
              label="Количество"
              placeholder="Количество"
              :rules="[...rules.positiveNumber]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" type="submit" block>Проверить</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p class="font-weight-bold">Профит:{{ predictRes }}</p>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";

import { CHANGE_BID_PRICE_REQUESTS_URL } from "../../../config/http";

export default {
  name: "EditBidsPopUp",
  mixins: [PopUpMixin, RoleMixin],
  data: () => ({
    // currency: null,
    price: "",
    count: "",
    predict: false,
    predictPrice: "",
    predictCount: "",
    predictRes: "",
    // dateMenu: false,
    // timeMenu: false,
    // date: moment().format("YYYY-MM-DD"),
    // time: moment().format("HH:mm:ss"),
    // isLowering: false,
  }),
  methods: {
    submitPredict() {
      this.isRequestWaiting = true;

      // const momentString = moment(
      //   `${this.date} ${this.time}`,
      //   "YYYY-MM-DD HH:mm:ss"
      // ).toISOString();

      // const timestamp = Date.parse(momentString);

      const body = {
        price: this.predictPrice,
        count: this.predictCount,
        // predict: this.predict,
      };

      this.$http
        .patch(
          CHANGE_BID_PRICE_REQUESTS_URL(this.itemCopy.userId, this.itemCopy.id),
          body
        )
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.predictRes = response.data.profit;
            // this.closePopUp();
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },

    submitAction() {
      this.isRequestWaiting = true;

      // const momentString = moment(
      //   `${this.date} ${this.time}`,
      //   "YYYY-MM-DD HH:mm:ss"
      // ).toISOString();

      // const timestamp = Date.parse(momentString);

      const body = {
        price: this.price,
        count: this.count,
        // predict: this.predict,
      };

      this.$http
        .post(
          CHANGE_BID_PRICE_REQUESTS_URL(this.itemCopy.userId, this.itemCopy.id),
          body
        )
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.closePopUp();
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        console.log(this.itemCopy);
        if (this.itemCopy) {
          this.currency = this.itemCopy.Currency?.codeName;
          this.date = moment(this.itemCopy.endDate).format("YYYY-MM-DD");
          this.time = moment(this.itemCopy.endDate).format("HH:mm:ss");
        } else {
          this.date = moment().format("YYYY-MM-DD");
          this.time = moment().format("HH:mm:ss");
        }
      }
    },
  },
  computed: {
    getCurrencies() {
      return this.$store.getters.currency;
    },
  },
  props: {
    userId: Number,
    bidId: Number,
  },
};
</script>

<style scoped></style>
