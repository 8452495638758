var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(false)?_c('v-row',[_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isCreateUserOpened = true}}},[_vm._v(" Создать профиль ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('DataTableTop',{attrs:{"isSeveralItemsSelected":_vm.isSeveralItemsSelected},on:{"edit":function($event){return _vm.openEditSeveralUsersPopUp()},"update":_vm.getUsersFromApi,"delete":function($event){return _vm.openDeletePopUp()}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-data-table',{staticClass:"clickable-table",attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.currentPage,"items-per-page":_vm.currentPerPage,"loading":_vm.isRequestWaiting,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
            'items-per-page-options': _vm.rowsPerPageItems,
          },"show-select":""},on:{"click:row":_vm.userClickHandler,"update:options":function($event){_vm.options=$event},"pagination":_vm.setPage},scopedSlots:_vm._u([{key:"header.email",fn:function(ref){
          var header = ref.header;
return [_c('FilterComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterEmailQuery),callback:function ($$v) {_vm.filterEmailQuery=$$v},expression:"filterEmailQuery"}})]}},{key:"header.firstName",fn:function(ref){
          var header = ref.header;
return [_c('FilterComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterFirstNameQuery),callback:function ($$v) {_vm.filterFirstNameQuery=$$v},expression:"filterFirstNameQuery"}})]}},{key:"header.lastName",fn:function(ref){
          var header = ref.header;
return [_c('FilterComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterLastNameQuery),callback:function ($$v) {_vm.filterLastNameQuery=$$v},expression:"filterLastNameQuery"}})]}},{key:"header.country",fn:function(ref){
          var header = ref.header;
return [_c('FilterComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterCountryQuery),callback:function ($$v) {_vm.filterCountryQuery=$$v},expression:"filterCountryQuery"}})]}},{key:"header.phone",fn:function(ref){
          var header = ref.header;
return [_c('FilterComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterPhoneQuery),callback:function ($$v) {_vm.filterPhoneQuery=$$v},expression:"filterPhoneQuery"}})]}},{key:"header.balance",fn:function(ref){
          var header = ref.header;
return [_c('FilterRangeComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterBalanceQuery),callback:function ($$v) {_vm.filterBalanceQuery=$$v},expression:"filterBalanceQuery"}})]}},{key:"header.createdAt",fn:function(ref){
          var header = ref.header;
return [_c('FilterDateRangeComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterCreatedAtQuery),callback:function ($$v) {_vm.filterCreatedAtQuery=$$v},expression:"filterCreatedAtQuery"}})]}},{key:"header.lastLoginDate",fn:function(ref){
          var header = ref.header;
return [_c('FilterDateRangeComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterlastLoginDateQuery),callback:function ($$v) {_vm.filterlastLoginDateQuery=$$v},expression:"filterlastLoginDateQuery"}})]}},{key:"header.processingStatusId",fn:function(ref){
          var header = ref.header;
return [_c('FilterProcessingStatusComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterProcessingStatusQuery),callback:function ($$v) {_vm.filterProcessingStatusQuery=$$v},expression:"filterProcessingStatusQuery"}})]}},{key:"header.LastComment",fn:function(ref){
          var header = ref.header;
return [_c('FilterComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterLastCommentQuery),callback:function ($$v) {_vm.filterLastCommentQuery=$$v},expression:"filterLastCommentQuery"}})]}},{key:"header.AffManagerAdmin.firstName",fn:function(ref){
          var header = ref.header;
return [_c('FilterManagersComponent',{attrs:{"header":header,"callCenterId":_vm.callCenterId,"role":"aff"},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterAffManagerQuery),callback:function ($$v) {_vm.filterAffManagerQuery=$$v},expression:"filterAffManagerQuery"}})]}},{key:"header.SaleAdmin.firstName",fn:function(ref){
          var header = ref.header;
return [_c('FilterManagersComponent',{attrs:{"header":header,"callCenterId":_vm.callCenterId,"specialFormatting":true,"role":"sale"},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterSaleAdminQuery),callback:function ($$v) {_vm.filterSaleAdminQuery=$$v},expression:"filterSaleAdminQuery"}})]}},{key:"header.RetAdmin.firstName",fn:function(ref){
          var header = ref.header;
return [_c('FilterManagersComponent',{attrs:{"header":header,"callCenterId":_vm.callCenterId,"specialFormatting":true,"role":"ret"},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterRetAdminQuery),callback:function ($$v) {_vm.filterRetAdminQuery=$$v},expression:"filterRetAdminQuery"}})]}},{key:"header.depositsCount",fn:function(ref){
          var header = ref.header;
return [_c('FilterRangeComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterDepositsCountQuery),callback:function ($$v) {_vm.filterDepositsCountQuery=$$v},expression:"filterDepositsCountQuery"}})]}},{key:"header.depositsSum",fn:function(ref){
          var header = ref.header;
return [_c('FilterRangeComponent',{attrs:{"header":header},on:{"update":_vm.getUsersFromApi},model:{value:(_vm.filterDepositsSumQuery),callback:function ($$v) {_vm.filterDepositsSumQuery=$$v},expression:"filterDepositsSumQuery"}})]}},{key:"header.verificationStatusId",fn:function(ref){return [_c('thead',[_c('tr',[_c('th',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-left":"6px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" При клике на иконку статуса верификации откроется новое всплывающее окно с выбором действий. Сам клик на иконку не изменит статус пользователя, кликать на иконку безопасно. ")])])],1)])])]}},{key:"item.icon",fn:function(ref){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-circle")])]}},{key:"item.email",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(item.email))])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(("(" + (item.isEmailVerified ? "Подтвержден" : "Не подтвержден") + ")"))+" ")])],1)]}},{key:"item.phone",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v(_vm._s(item.phone))])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyPhoneToClipboard(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-content-copy")])],1)],1)],1)]}},{key:"item.balance",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseBigNumber(item.balance))+" ")]}},{key:"item.AffManagerAdmin.firstName",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s((" " + (item.AffManagerAdmin ? ((item.AffManagerAdmin.firstName) + " " + (item.AffManagerAdmin.lastName)) : "")))+" ")]}},{key:"item.SaleAdmin.firstName",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s((" " + (item.SaleAdmin ? ((item.SaleAdmin.firstName) + " " + (item.SaleAdmin.lastName)) : "")))+" ")]}},{key:"item.RetAdmin.firstName",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s((" " + (item.RetAdmin ? ((item.RetAdmin.firstName) + " " + (item.RetAdmin.lastName)) : "")))+" ")]}},{key:"item.depositsSum",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseBigNumber(item.depositsSum))+" ")]}},{key:"item.isFirstCall",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.isFirstCall ? "Да" : "Нет")+" ")]}},{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormatDate(item.createdAt))+" ")]}},{key:"item.lastLoginDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormatDate(item.lastLoginDate))+" ")]}},{key:"item.LastComment",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.LastComment ? item.LastComment.comment : "")+" ")]}},{key:"item.processingStatusId",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"inline":"","left":"","dot":"","color":_vm.processingStatusColor(item.processingStatusId)}},[_c('span',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.openEditProcessingStatusPopUp(item)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.ProcessingStatus ? item.ProcessingStatus.name : null)+" ")])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.ProcessingStatus ? item.ProcessingStatus.description : null)+" ")])])]}},{key:"item.verificationStatusId",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openVerificationPopUp(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.getVerificationIcon(item)))])],1)]}},{key:"item.isOnline",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":item.isOnline ? 'green' : 'red',"size":"18"}},'v-avatar',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.isOnline ? "Пользователь в сети" : "Пользователь не в сети")+" ")])])]}},{key:"item.isOnTrading",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isOnTrading ? "Да" : "Нет"))])]}},{key:"item.edit",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openEditPopUp(item)}}},[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}},{key:"item.delete",fn:function(ref){
          var item = ref.item;
return [(_vm.isSuperAdminPermission)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"red","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDeletePopUp([item])}}},[_c('v-icon',[_vm._v("mdi-account-remove")])],1):_vm._e()]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1),_c('DetailUserPopUp',{attrs:{"isOpened":_vm.isDetailPopUpOpened,"selectedItem":_vm.selectedItem},on:{"openBids":_vm.openBidsHandler,"openActions":_vm.openActionsHandler,"openEdit":function($event){return _vm.openEditPopUp($event)},"close":_vm.closeDetailPopUp}}),_c('VerificationUserPopUp',{attrs:{"isOpened":_vm.isVerificationUserOpened,"selectedItem":_vm.selectedItem},on:{"update":function($event){return _vm.updateHandler($event)},"close":_vm.closeVerificationPopUp}}),_c('EditUserPopUp',{attrs:{"isOpened":_vm.isEditPopUpOpened,"selectedItem":_vm.selectedItem},on:{"update":function($event){return _vm.updateHandler($event)},"refresh":_vm.getUsersFromApi,"close":_vm.closeEditPopUp}}),_c('DetailBidsPopUp',{attrs:{"isOpened":_vm.isDetailBidsPopUpOpened,"selectedItem":_vm.selectedItem},on:{"close":_vm.closeBidsHandler}}),_c('DetailActionsPopUp',{attrs:{"isOpened":_vm.isDetailActionsPopUpOpened,"selectedItem":_vm.selectedItem},on:{"close":_vm.closeActionsHandler}}),_c('EditSeveralUsersPopUp',{attrs:{"isOpened":_vm.isEditSeveralPopUpOpened,"selectedItem":_vm.selectedItems},on:{"update":function($event){return _vm.updateSeveralItems($event)},"close":_vm.closeEditSeveralUsersPopUp}}),_c('EditProcessingStatusPopUp',{attrs:{"isOpened":_vm.isEditProcessingStatusPopUpOpened,"selectedItem":_vm.selectedItem},on:{"update":function($event){return _vm.updateHandler($event)},"refresh":_vm.getUsersFromApi,"close":_vm.closeEditProcessingStatusPopUp}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }