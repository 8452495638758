<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Загрузить документ </span>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col cols="4" align="center">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="auto" align="center">
              <input
                ref="fileInput"
                style="display: none"
                type="file"
                @change="uploadFile"
              />

              <v-btn @click="$refs.fileInput.click()" color="primary">
                {{ this.file ? "Выбрать другой" : "Выбрать файл" }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="file"
            align="center"
            justify="center"
            no-gutters
            style="margin-top: 2rem"
          >
            <v-col cols="auto" align="center">
              <v-btn @click="sendFile" color="primary"> Отправить файл </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8" align="center" v-if="file">
          <v-row>
            <v-col>Название: </v-col>
            <v-col>{{ fileName }} </v-col>
          </v-row>
          <v-row>
            <v-col>Тип: </v-col>
            <v-col>{{ fileType }} </v-col>
          </v-row>
          <v-row>
            <v-col>Размер: </v-col>
            <v-col>{{ fileSize }} </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import { GET_UPLOAD_DOCUMENT_URL } from "../../../config/http";

export default {
  name: "UploadDocumentPopup",
  mixins: [PopUpMixin],
  data: () => {
    return {
      file: null,
    };
  },
  methods: {
    closePopUp() {
      this.file = null;
      this.$emit("close");
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        this.$nextTick(() => {
          this.$refs.form?.resetValidation();
        });
      }
    },
    uploadFile(e) {
      try {
        const file = e.target.files[0];
        file ? (this.file = file) : "";
        console.log(this.file);
      } catch (e) {
        console.log(e);
      }
    },
    bytesToSize(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "n/a";
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      if (i === 0) return `${bytes} ${sizes[i]})`;
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
    sendFile() {
      this.isRequestWaiting = true;
      this.$http
        .get(GET_UPLOAD_DOCUMENT_URL(this.fileName))
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            // const response = data.data;
            // this.items = response.items;
            // this.totalItems = response.count;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e);
          this.isRequestWaiting = false;
        });
    },
  },
  computed: {
    fileName() {
      return this.file ? this.file.name : "";
    },
    fileType() {
      const str = this.fileName;
      const index = str.lastIndexOf(".");

      if (this.file) {
        if (this.file.type) return this.file.type;
        else return this.fileName.slice(index + 1);
      } else return "";
    },
    fileSize() {
      return this.bytesToSize(this.file.size);
    },
    fileSrc() {
      return URL.createObjectURL(this.file);
    },
  },
};
</script>

<style scoped></style>
