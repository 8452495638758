var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000","persistent":""},on:{"click:outside":_vm.closePopUp},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('v-card',{staticClass:"px-6 py-4"},[_c('v-row',[_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closePopUp}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Информация о ставках")])])],1),(_vm.itemCopy)?_c('v-row',{staticClass:"bids-table-container",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bids,"loading":_vm.isRequestWaiting},scopedSlots:_vm._u([{key:"item.endPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profit.toFixed(3))+"$ ")]}},{key:"item.Currency.predict",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Currency.predict ? "Выше" : "Ниже")+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.endDate ? _vm.getFormatDateTime(item.endDate) : "-")+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","disabled":item.endPrice !== null,"small":"","block":""},on:{"click":function($event){return _vm.openEditBids(item)}}},[_vm._v(" "+_vm._s(item.endPrice !== null ? "Сделка закрыта" : "Повлиять на ставку")+" ")])]}}],null,false,2170646106)})],1)],1):_vm._e()],1),_c('EditBidsPopUp',{attrs:{"isOpened":_vm.isEditPopUpOpened,"selectedItem":_vm.selectedBid,"userId":_vm.getUserId,"bidId":_vm.getBidId},on:{"close":_vm.closeEditBids}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }